import { observer } from 'mobx-react-lite';

import { ButtonPrimary } from '@shared-atom/button/button-primary';
import { ButtonReject } from '@shared-atom/button/button-reject';
import { ButtonSecondary } from '@shared-atom/button/button-secondary';
import { Tooltip } from '@shared-atom/tooltip/tooltip';
import { ViewPageLoader } from '@shared-component/page-loader/page-loader';

import { Breadcrumbs } from '@component/breadcrumbs/breadcrumbs';
import { BreadCrumbsWrapper, HeaderWrapper, PageTitle } from '@page/common/common.styles';
import {
    NotificationInstancePageContent,
    NotificationInstancePageDescription,
} from '@page/emails-and-notifications/emails-and-notifications.page.styles';
import { useCreateNotificationPage } from '@page/emails-and-notifications/notification/view/view-notification.hook';
import { getBreadcrumbList } from '@page/emails-and-notifications/notification/view/view-notification.options';
import {
    ViewNotificationButtonsWrapper,
    ViewNotificationContent,
    ViewNotificationInfoBlock,
    ViewNotificationInfoContent,
    ViewNotificationInfoContentBadge,
    ViewNotificationInfoTitle,
} from '@page/emails-and-notifications/notification/view/view-notification.styles';

export const ViewNotificationPage = observer(() => {
    const { notification, isAdminGetNotificationLoading, isLoading, onSendNotification, onDeleteNotification } =
        useCreateNotificationPage();

    if (isAdminGetNotificationLoading) {
        return <ViewPageLoader />;
    }

    return (
        <>
            <BreadCrumbsWrapper>
                <Breadcrumbs breadcrumbs={getBreadcrumbList(notification?.id ?? '')} />
            </BreadCrumbsWrapper>
            <HeaderWrapper>
                <PageTitle>Notification {notification?.id ?? ''}</PageTitle>
            </HeaderWrapper>
            <NotificationInstancePageContent>
                <NotificationInstancePageDescription>
                    Notification will be sent to next users:
                </NotificationInstancePageDescription>
                <ViewNotificationContent>
                    {notification?.userType && (
                        <ViewNotificationInfoBlock>
                            <ViewNotificationInfoTitle>User yype</ViewNotificationInfoTitle>
                            <ViewNotificationInfoContent>{notification?.userType ?? '-'}</ViewNotificationInfoContent>
                        </ViewNotificationInfoBlock>
                    )}
                    {notification?.userStatus && (
                        <ViewNotificationInfoBlock>
                            <ViewNotificationInfoTitle>User yype</ViewNotificationInfoTitle>
                            <ViewNotificationInfoContent>{notification?.userStatus ?? '-'}</ViewNotificationInfoContent>
                        </ViewNotificationInfoBlock>
                    )}
                    {notification && notification?.clients.length > 0 && (
                        <ViewNotificationInfoBlock>
                            <ViewNotificationInfoTitle>Recipient ids</ViewNotificationInfoTitle>
                            <ViewNotificationInfoContent>
                                {notification.clients.map(({ id }) => (
                                    <ViewNotificationInfoContentBadge key={id}>{id}</ViewNotificationInfoContentBadge>
                                ))}
                            </ViewNotificationInfoContent>
                        </ViewNotificationInfoBlock>
                    )}
                    <ViewNotificationInfoBlock>
                        <ViewNotificationInfoTitle>Subject</ViewNotificationInfoTitle>
                        <ViewNotificationInfoContent>{notification?.subject ?? '-'}</ViewNotificationInfoContent>
                    </ViewNotificationInfoBlock>
                    <ViewNotificationInfoBlock>
                        <ViewNotificationInfoTitle>Notification text</ViewNotificationInfoTitle>
                        <ViewNotificationInfoContent>{notification?.template ?? '-'}</ViewNotificationInfoContent>
                    </ViewNotificationInfoBlock>
                    <ViewNotificationButtonsWrapper>
                        <ButtonPrimary onClick={onSendNotification} disabled={isLoading}>
                            Send
                        </ButtonPrimary>
                        <Tooltip content="Will be available soon" dataFor="edit-notification-button-tooltip">
                            <ButtonSecondary>Edit</ButtonSecondary>
                        </Tooltip>
                        <ButtonReject onClick={onDeleteNotification} disabled={isLoading}>
                            Delete
                        </ButtonReject>
                    </ViewNotificationButtonsWrapper>
                </ViewNotificationContent>
            </NotificationInstancePageContent>
        </>
    );
});
