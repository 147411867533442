import { Form, Formik } from 'formik';

import { ClientSummaryEditPhoneFormFields } from '@page/common/client-summary/client-summary-edit-phone-form/client-summary-edit-phone-form-fields/client-summary-edit-phone-form-fields';
import { useClientSummaryEditPhoneForm } from '@page/common/client-summary/client-summary-edit-phone-form/client-summary-edit-phone-form.hook';
import { ClientSummaryEditPhoneFormInterface } from '@page/common/client-summary/client-summary-edit-phone-form/client-summary-edit-phone-form.interface';
import {
    EditFormWrapper,
    EditIcon,
} from '@page/common/client-summary/client-summary-edit-phone-form/client-summary-edit-phone-form.styles';
import { clientSummaryEditPhoneFormValidationSchema } from '@page/common/client-summary/client-summary-edit-phone-form/client-summary-edit-phone-form.validation';

export const ClientSummaryEditPhoneForm = ({ phone, userId, updateUserPhone }: ClientSummaryEditPhoneFormInterface) => {
    const {
        error,
        formRef,
        isFormVisible,
        isLoading,
        onSubmit,
        setCanCloseFormOnOutsideClick,
        setFormVisible,
        shouldOpenOtpModal,
        reset,
    } = useClientSummaryEditPhoneForm({ phone });

    return (
        <EditFormWrapper>
            {isFormVisible ? (
                <Formik
                    initialValues={{ phone, userId }}
                    onSubmit={onSubmit}
                    validationSchema={clientSummaryEditPhoneFormValidationSchema}
                    validateOnChange
                >
                    {({ values }) => (
                        <Form
                            ref={formRef}
                            onBlur={() => onSubmit(values)}
                            onFocus={() => setCanCloseFormOnOutsideClick(true)}
                        >
                            <ClientSummaryEditPhoneFormFields
                                adminStartChangPhoneError={error}
                                isAdminStartChangPhoneLoading={isLoading}
                                onSubmit={onSubmit}
                                setFormVisible={setFormVisible}
                                shouldOpenOtpModal={shouldOpenOtpModal}
                                updateUserPhone={updateUserPhone}
                                reset={reset}
                            />
                        </Form>
                    )}
                </Formik>
            ) : (
                <>
                    {phone}
                    <EditIcon onClick={() => setFormVisible(!isFormVisible)} />
                </>
            )}
        </EditFormWrapper>
    );
};
