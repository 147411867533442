import { useFormikContext } from 'formik';
import { useEffect } from 'react';

import { Input } from '@shared-component/input/input';
import { TextArea } from '@shared-component/input/textarea/textarea';
import { Select } from '@shared-component/select/select';
import { FormHalfRow } from '@shared-form/form-common/form-common.styles';
import {
    userStatusValues,
    userTypeValues,
} from '@shared-form/template-notification-form/template-notification-form-by-type-and-status-fields/template-notification-form-by-type-and-status-fields.options';
import { TemplateNotificationFormEnum } from '@shared-form/template-notification-form/template-notification-form.enum';

export const TemplateNotificationFormByTypeAndStatusFields = () => {
    const { resetForm } = useFormikContext();

    useEffect(() => {
        resetForm();
    }, []);

    return (
        <>
            <FormHalfRow>
                <Select name={TemplateNotificationFormEnum.UserType} title="Type Client" options={userTypeValues} />
                <Select name={TemplateNotificationFormEnum.UserStatus} title="Status" options={userStatusValues} />
            </FormHalfRow>
            <Input name={TemplateNotificationFormEnum.Subject} title="Subject" />
            <TextArea title="Notification text" name={TemplateNotificationFormEnum.Template} maxLength={200} />
        </>
    );
};
