import { useContext } from 'react';

import { ModelContext } from '@shared-component/modal/modal-context';
import { OtpLoginStep } from '@shared-component/otp-login-step/otp-login-step';

import { ModalWrapper } from '@component/modal/admin-modal.styles';

export const SendOTPModal = () => {
    const {
        props: { componentProps },
    } = useContext(ModelContext);
    const { phone, onResendOtpCode, onSubmit, error } = componentProps ?? {};

    return (
        <ModalWrapper>
            <OtpLoginStep phone={phone} onResendOtpCode={onResendOtpCode} onSubmit={onSubmit} error={error} />
        </ModalWrapper>
    );
};
