import { useEffect } from 'react';

import { useCurrentNavigation, useRouterNavigate } from '@shared-hook/navigation/use-current-navigation.hook';

import { PageEnum } from '@enum/page.enum';
import { EmailsAndNotificationsTableFilterFormEnum } from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications-table-filter/emails-and-notifications-table-filter-form/emails-and-notifications-table-filter-form.enum';
import { FilterByNotificationInstanceInterface } from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications-table-filter/filter-by-notification-instance-type/filter-by-notification-instance-type.interface';
import { NotificationInstanceTypeEnum } from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications.enum';

export const useFilterByNotificationInstanceType = ({
    notificationInstanceTypeFilter,
    onNotificationInstanceTypeChange,
}: FilterByNotificationInstanceInterface) => {
    const navigate = useRouterNavigate();
    const { searchParams: params } = useCurrentNavigation();

    const { notificationInstanceType } = params;

    useEffect(() => {
        void (
            notificationInstanceTypeFilter !== undefined &&
            navigate(PageEnum.EmailsAndNotifications, {
                ...params,
                [EmailsAndNotificationsTableFilterFormEnum.NotificationInstanceType]:
                    notificationInstanceTypeFilter === null
                        ? NotificationInstanceTypeEnum.Templates
                        : notificationInstanceTypeFilter,
            })
        );
    }, [notificationInstanceTypeFilter]);

    useEffect(
        () =>
            void onNotificationInstanceTypeChange(
                notificationInstanceType !== undefined &&
                    notificationInstanceType !== NotificationInstanceTypeEnum.Templates
                    ? notificationInstanceType
                    : NotificationInstanceTypeEnum.Templates
            ),
        [notificationInstanceType]
    );
};
