import { Column } from 'react-table';

import { Tooltip } from '@shared-atom/tooltip/tooltip';
import { TableHeaderCellTitle, TableHeaderSortingIcon } from '@shared-component/table/table-head/table-head.styles';
import { Notification, Template } from '@shared-graphql';

export const EmailsAndNotificationsOptions: Column<Template | Notification>[] = [
    {
        Header: (
            <>
                <TableHeaderCellTitle>ID</TableHeaderCellTitle>
                <Tooltip content="Sorting will be available soon" dataFor="sorting-for-notifID-tooltip">
                    <TableHeaderSortingIcon />
                </Tooltip>
            </>
        ),
        id: 'id',
        width: 1,
    },
    {
        Header: (
            <>
                <TableHeaderCellTitle>Recipients</TableHeaderCellTitle>
                <Tooltip content="Sorting will be available soon" dataFor="sorting-for-recipients-tooltip">
                    <TableHeaderSortingIcon />
                </Tooltip>
            </>
        ),
        id: 'recipients',
        width: 2,
    },
    {
        Header: (
            <>
                <TableHeaderCellTitle>Subject</TableHeaderCellTitle>
                <Tooltip content="Sorting will be available soon" dataFor="sorting-for-subject-tooltip">
                    <TableHeaderSortingIcon />
                </Tooltip>
            </>
        ),
        id: 'subject',
        width: 2,
    },
    {
        Header: (
            <>
                <TableHeaderCellTitle>Date</TableHeaderCellTitle>
                <Tooltip content="Sorting will be available soon" dataFor="sorting-for-creationDate-tooltip">
                    <TableHeaderSortingIcon />
                </Tooltip>
            </>
        ),
        id: 'updatedAt',
        width: 1.3,
    },
    {
        Header: (
            <>
                <TableHeaderCellTitle>Client type</TableHeaderCellTitle>
                <Tooltip content="Sorting will be available soon" dataFor="sorting-for-clientType-tooltip">
                    <TableHeaderSortingIcon />
                </Tooltip>
            </>
        ),
        id: 'client-type',
        width: 0.8,
    },
    {
        Header: (
            <>
                <TableHeaderCellTitle>Status</TableHeaderCellTitle>
                <Tooltip content="Sorting will be available soon" dataFor="sorting-for-clientStatus-tooltip">
                    <TableHeaderSortingIcon />
                </Tooltip>
            </>
        ),
        id: 'status',
        width: 0.8,
    },
    {
        Header: <TableHeaderCellTitle>Actions</TableHeaderCellTitle>,
        id: 'actions',
        width: 0.8,
    },
];
