import { AccountAdministration, CommissionType, OperationType, PaymentType, UserType } from '@shared-graphql';

export const initialAccountAdministration: AccountAdministration = {
    accountClosing: 0,
    accountMaintenance: 0,
    accountOpening: 0,
    balanceFee: 0,
    complexStructure: 0,
    foreignCurrencies: 0,
    urgentAccountOpening: 0,
    lastUpdated: new Date(),
    userType: null,
};

export const initialExchangeCommission = {
    minAmount: 0,
    maxAmount: 0,
    percentCommission: 0,
};

export const initialCommissionLegal = {
    id: '',
    name: '',
    paymentType: PaymentType.sepa,
    type: CommissionType.Payment,
    currency: 'EUR',
    lastUpdated: new Date(),
    commissions: [
        {
            minAmount: 0,
            maxAmount: 0,
            staticCommission: 0,
            percentCommission: 0,
            minFee: 0,
            maxFee: 0,
        },
    ],
    isDefault: true,
    operation: OperationType.Debit,
    isSepa: true,
    clientId: '',
    userType: UserType.Legal,
    lokaliseKey: '',
};

export const initialCommissionPrivate = {
    ...initialCommissionLegal,
    userType: UserType.Legal,
};

export const initialCommissionClient = {
    clientId: 'Legal',
};

export const initialCommissionUserType = 'Legal';

export const initialNewCommission = {
    maxAmount: 0,
    percentCommission: 0,
    staticCommission: 0,
};

export const initialAllClient = [
    {
        value: 'Legal',
        label: 'Legal',
        default: true,
    },
    {
        value: 'Private',
        label: 'Private',
    },
];

export const initialSupportsAdministration = {
    amount: 0,
    reason: '',
    title: '',
};
