import styled from 'styled-components';

import { ButtonPrimaryStyle } from '@shared-atom/button/button.style';
import { CloseIcon } from '@shared-atom/close/close.styles';
import { Typography } from '@shared-atom/typography/typography';
import { Concrete, DimGray } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';

export const AddValueButton = styled(ButtonPrimaryStyle)`
    width: 76px;
    height: 36px;
`;

export const ValueListWrapper = styled.div`
    margin-top: 16px;
    gap: 12px;
    ${Flex.Column.Style};
`;
export const SingleValue = styled.div`
    ${Flex.Row.VerticalCenter.Style};
    border-radius: 8px;
    background: ${Concrete};
    padding: 8px 12px;
    gap: 6px;
    color: ${DimGray};
    ${Typography.Paragraph14.Normal.Style};
    line-height: normal;
    width: fit-content;
`;
export const ButtonsWrapper = styled.div`
    gap: 12px;
    ${Flex.Row.VerticalCenter.Style};
    padding: 0 10px;
`;
export const DeleteIcon = styled(CloseIcon)`
    cursor: pointer;
`;
