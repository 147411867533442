import { observer } from 'mobx-react-lite';

import { DateComponent } from '@shared-atom/date/date';
import { Translate } from '@shared-atom/translate/translate';
import { CountryCodeType } from '@shared-component/country-select/country-select.type';
import { UserActivatedStatus, UserFinancialStatus, UserGender } from '@shared-graphql';
import { useIfxCallbackUrl } from '@shared-hook/ifx-callback-url/ifx-callback-url.hook';
import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { ClickEventType } from '@shared-type/click-event.type';
import { isString, isTrue } from '@shared-util/is-data';

import { ClientSummaryEditPhoneForm } from '@page/common/client-summary/client-summary-edit-phone-form/client-summary-edit-phone-form';
import { CountryLabel } from '@page/common/client-summary/private-client-summary-country-label/private-client-summary-country-label';
import { GridRow } from '@page/common/client-summary/private-client-summary-row/private-client-summary-row';
import {
    SummaryGroup,
    SummaryGroupTitle,
} from '@page/common/client-summary/private-client-summary-row/private-client-summary-row.styles';
import { usePrivateClientActionsSelector } from '@selector/private-client/use-private-client.actions-selector';

import { SummaryStatusButtons } from './private-client-summary-status-buttons/private-client-summary-status-buttons';
import { usePrivateClientSummary } from './private-client-summary.hook';
import { BlockFooter, BlockWrapper, GridColumn } from './private-client-summary.styles';

export const PrivateClientSummary = observer(() => {
    const [
        {
            id,
            status,
            elcoinId,
            verificationRejectionTitle,
            verificationRejectionDate,
            moneyTurnover,
            sourceOfFunds,
            reasonAccount,
            isApplicationProfileCompleted,
            email,
            address,
            profile,
            phone,
            termsAccepted: isTermsAccepted,
        },
        handleAction,
    ] = usePrivateClientSummary();
    const { setClientPhone } = usePrivateClientActionsSelector();

    const { callbackURL, getIbanqCallbackUrl } = useIfxCallbackUrl({ clientId: id });

    const purposeUsingAccountOptions = [
        {
            value: 'savings_and_expenses_for_own_needs',
            label: <Translate langKey={LocalizationEnum.SavingsAndExpensesForOwnNeeds} />,
        },
        { value: 'investment', label: <Translate langKey={LocalizationEnum.CommonInvestment} /> },
        {
            value: 'buying_and_selling_real_estate',
            label: <Translate langKey={LocalizationEnum.BuyingAndSellingRealEstate} />,
        },
        { value: 'sale-purchase_of_a_company', label: <Translate langKey={LocalizationEnum.SalePurchaseOfACompany} /> },
        { value: 'other', label: <Translate langKey={LocalizationEnum.RegistrationBusinessStepOneCategory7} /> },
    ];

    const monthlyIncomeOptions = [
        { value: 'less_than_2000', label: <Translate langKey={LocalizationEnum.LessThanTwoThousands} /> },
        { value: '2000-5000', label: '2 000 - 5 000' },
        { value: '5000-10000', label: '5 000 -10 000' },
        { value: '10000+', label: '10 000+' },
    ];

    const sourceIncomeOptions = [
        { value: 'salary', label: <Translate langKey={LocalizationEnum.CommonSalary} /> },
        { value: 'saving', label: <Translate langKey={LocalizationEnum.CommonSaving} /> },
        { value: 'interest_or_dividends', label: <Translate langKey={LocalizationEnum.CommonInterestOrDividends} /> },
        { value: 'sale_of_property', label: <Translate langKey={LocalizationEnum.CommonSaleOfProperty} /> },
        { value: 'sale_of_shares', label: <Translate langKey={LocalizationEnum.CommonSaleOfShares} /> },
        {
            value: 'income_from_self_employed_activities',
            label: <Translate langKey={LocalizationEnum.IncomeFromSelfEmployedActivities} />,
        },
        { value: 'inheritance_or_gift', label: <Translate langKey={LocalizationEnum.InheritanceOrGift} /> },
        { value: 'rental_income', label: <Translate langKey={LocalizationEnum.RentalIncome} /> },
        { value: 'other', label: <Translate langKey={LocalizationEnum.RegistrationBusinessStepOneCategory7} /> },
    ];

    const employedFullLabel = useLocalizationText(LocalizationEnum.PrivateProfileFormEmployedFullOption);
    const employedPartLabel = useLocalizationText(LocalizationEnum.PrivateProfileFormEmployedPartOption);
    const selfEmployedLabel = useLocalizationText(LocalizationEnum.PrivateProfileFormSelfEmployedOption);
    const retired = useLocalizationText(LocalizationEnum.PrivateProfileFormRetiredOption);
    const unemployed = useLocalizationText(LocalizationEnum.PrivateProfileFormUnemployedOption);
    const male = useLocalizationText(LocalizationEnum.PrivateFormGenderMaleOption);
    const female = useLocalizationText(LocalizationEnum.PrivateFormGenderFemaleOption);

    const financialStatusToTextMapping = {
        [UserFinancialStatus.EmployedFull]: employedFullLabel,
        [UserFinancialStatus.EmployedPart]: employedPartLabel,
        [UserFinancialStatus.SelfEmployed]: selfEmployedLabel,
        [UserFinancialStatus.Unemployed]: unemployed,
        [UserFinancialStatus.Retired]: retired,
    };
    const userGenderToTextMapping = {
        [UserGender.Male]: male,
        [UserGender.Female]: female,
    };

    const shouldRenderVerificationRejectionDate = isString(verificationRejectionTitle);

    return (
        <BlockWrapper>
            <SummaryGroup>
                <SummaryGroupTitle>General information</SummaryGroupTitle>
                <GridColumn>
                    <GridRow title="Client ID" data={id} />
                    <GridRow title="Email" data={email} />
                    <GridRow title="Code" data={elcoinId} />
                    <GridRow title="Status" data={status} />
                    {shouldRenderVerificationRejectionDate && (
                        <GridRow title={verificationRejectionTitle} data={verificationRejectionDate} />
                    )}
                </GridColumn>
            </SummaryGroup>
            <SummaryGroup>
                <SummaryGroupTitle>Questionnaire</SummaryGroupTitle>
                <GridColumn>
                    <GridRow
                        title="Application month income"
                        data={monthlyIncomeOptions.find(m => m.value === moneyTurnover)?.label}
                    />
                    <GridRow
                        title="What is your source of income"
                        data={purposeUsingAccountOptions.find(s => s.value === reasonAccount)?.label}
                    />
                    <GridRow
                        title="What is the purpose of using SENDS account?"
                        data={sourceIncomeOptions.find(p => p.value === sourceOfFunds)?.label}
                    />
                </GridColumn>
            </SummaryGroup>
            <SummaryGroup>
                <SummaryGroupTitle>Personal Info</SummaryGroupTitle>
                <GridColumn>
                    <GridRow title="First name" data={profile?.name} />
                    <GridRow title="Last name" data={profile?.lastName} />
                    <GridRow title="Middle name" data={profile?.thirdName} />
                    <GridRow
                        title="Financial status"
                        data={financialStatusToTextMapping[profile?.financialStatus as UserFinancialStatus]}
                    />
                    <GridRow title="Gender" data={userGenderToTextMapping[profile?.gender as UserGender]} />
                    <GridRow
                        title="Nationality"
                        data={<CountryLabel countryCode={profile?.nationality as CountryCodeType} />}
                    />
                    <GridRow
                        title="Date of birth"
                        data={profile?.dateBirthday ? <DateComponent date={profile?.dateBirthday as Date} /> : '-'}
                    />
                    <GridRow
                        title="Phone"
                        data={<ClientSummaryEditPhoneForm phone={phone} userId={id} updateUserPhone={setClientPhone} />}
                    />
                    <GridRow title="Is Terms accepted" data={isTrue(isTermsAccepted) ? 'Yes' : 'No'} />
                </GridColumn>
            </SummaryGroup>
            <SummaryGroup>
                <SummaryGroupTitle>Address</SummaryGroupTitle>
                <GridColumn>
                    <GridRow
                        title="Country"
                        data={
                            isTrue(address?.country) ? (
                                <CountryLabel countryCode={address?.country as CountryCodeType} />
                            ) : (
                                '-'
                            )
                        }
                    />
                    <GridRow title="City" data={address?.city ?? '-'} />
                    <GridRow title="Street" data={address?.street ?? '-'} />
                    <GridRow title="Building" data={address?.building ?? '-'} />
                    <GridRow title="Zip" data={address?.zip ?? '-'} />
                </GridColumn>
            </SummaryGroup>
            {isTrue(callbackURL) && (
                <SummaryGroup>
                    <GridColumn>
                        <GridRow title="IFX Callback URL" data={callbackURL} isCopyEnabled />
                    </GridColumn>
                </SummaryGroup>
            )}
            <BlockFooter>
                <SummaryStatusButtons
                    handleAction={handleAction}
                    status={status as UserActivatedStatus}
                    isApplicationProfileCompleted={isApplicationProfileCompleted}
                    getIbanqCallbackUrl={getIbanqCallbackUrl as ClickEventType}
                />
            </BlockFooter>
        </BlockWrapper>
    );
});
