// import { parse } from 'date-fns';

// import { isTrue } from '@shared-util/is-data';

export const getFilters = ({
    // search, createdTo, createdFrom, clientType, clientStatus,
    limit,
    offset,
}: Record<string, any>) => ({
    limit,
    offset,
    // paymentDate: {
    //     gte: isTrue(createdFrom) ? parse(createdFrom, 'dd-MM-yyyy', new Date()) : null,
    //     lte: isTrue(createdTo) ? parse(createdTo, 'dd-MM-yyyy', new Date()) : null,
    // },
    // search,
    // clientType: isTrue(clientType) && clientType !== EmailsAndNotificationsEnum.All ? clientType : null,
    // clientStatus: isTrue(clientStatus) && clientStatus !== EmailsAndNotificationsEnum.All ? clientStatus : null,
});
