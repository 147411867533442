import { OperationType, PaymentEntityType, Transaction } from '@shared-graphql';

export const getBeneficiaryName = ({ operation, beneficiary, sender, client }: Transaction) => {
    const { beneficiaryEntityType, beneficiaryCompanyName, beneficiaryFirstName, beneficiaryLastName } =
        beneficiary ?? {};

    const isCreditOperation = operation === OperationType.Credit;
    const isSystemOperation = operation === OperationType.System;

    const beneficiaryName =
        beneficiaryEntityType === PaymentEntityType.individual
            ? `${beneficiaryFirstName} ${beneficiaryLastName}`
            : (beneficiaryCompanyName as string);

    const agentName = (isCreditOperation ? sender?.name : beneficiaryName) ?? client?.name;

    return isSystemOperation ? OperationType.System : agentName?.replace(/\s+/g, ' ').trim() ?? '';
};
