import { useMemo } from 'react';

import { CURRENCY_INITIALS } from '@shared-component/cards/cards.options';
import { DateFormatWithTime } from '@shared-component/input/input-date/input-date-format.helper';
import { Address, CompanyActivatedStatus, CompanyBiggestClient } from '@shared-graphql';
import { useLocalizationStore } from '@shared-hook/localization/use-localization-store.hook';
import { OnEventEmptyType, OnEventType } from '@shared-type/on-event.type';
import { getDate } from '@shared-util/get-date';

import { VerificationRejectionTitleEnum } from '@page/common/client-summary/client-summary.enum';
import { getVerificationRejectionTitle } from '@page/legal-client/legal-client-summary/legal-client-summary.utils';
import { useCompanyActionsSelector } from '@selector/company/use-company.actions-selector';
import { useCompanySelector } from '@selector/company/use-company.selector';

interface LegalClientSummaryInterface {
    id: string;
    companyName: string;
    clientStatus: string;
    country: string | null;
    moneyTurnover: string | null;
    moneyTurnoverCurrency: string;
    isApplicationProfileCompleted: boolean;
    companySite: string | null;
    verificationRejectionDate: string;
    verificationRejectionTitle: VerificationRejectionTitleEnum | null;
    status: CompanyActivatedStatus;
    numberOfPersons: string;
    numberOfCustomers: string;
    isAccountInAnotherBank: string;
    isSubmitFinancialTaxReport: string;
    spendPlan: string;
    preferredAccountCurrency: string;
    industryDescription: string;
    isCompanyAddressSame: boolean;
    legalAddress: Address;
    postalAddress: Address;
    biggestClient: CompanyBiggestClient | null;
    registrationDate: Date | number | null;
    email: string;
    elcoinId: string | null;
    phone: string;
    termsAccepted: number | null;
    userId: string;
}

const ACTIVATED_STATUSES = [CompanyActivatedStatus.Approved, CompanyActivatedStatus.Blocked];
const NO_DATA = '-';

const getYesNoResult = (value: boolean | null | undefined): string => {
    if (value === true) {
        return 'YES';
    }
    if (value === false) {
        return 'NO';
    }
    return 'not defined';
};

const getNotDefinedAddress = (address: Address | null): Address => ({
    city: address?.city ?? 'not defined',
    street: address?.street ?? 'not defined',
    building: address?.building ?? 'not defined',
    zip: address?.zip ?? 'not defined',
    country: address?.country ?? 'not defined',
    id: address?.id ?? null,
});

export const useLegalClientSummary = (): [
    LegalClientSummaryInterface,
    OnEventType<CompanyActivatedStatus, OnEventEmptyType>
] => {
    const [
        {
            id,
            companyName,
            status,
            elcoinId,
            statuses,
            companyStatus,
            registrationCountry,
            companyService,
            industryDescription,
            legalAddress,
            postalAddress,
            isCompanyAddressSame,
            companySite,
            biggestClient,
            registrationDate,
            owner,
        },
    ] = useCompanySelector();

    const { updateCompanyStatus } = useCompanyActionsSelector();

    const { lang } = useLocalizationStore();

    const [lastActiveStatus] = statuses;

    const currentStatusDataToDisplay = useMemo(
        () => statuses.find(element => ACTIVATED_STATUSES.includes(element.status)) ?? lastActiveStatus,
        [lastActiveStatus]
    );

    const handleAction = (actionStatus: CompanyActivatedStatus) => () => updateCompanyStatus(actionStatus);

    const isApplicationProfileCompleted = useMemo(
        () => Object.values(companyStatus).every(current => current),
        [companyStatus]
    );

    const verificationRejectionTitle = getVerificationRejectionTitle(currentStatusDataToDisplay?.status);

    const initialData = {
        id,
        companyName: companyName ?? NO_DATA,
        status,
        elcoinId,
        clientStatus: status,
        verificationRejectionTitle,
        verificationRejectionDate:
            currentStatusDataToDisplay?.date !== undefined
                ? getDate(new Date(currentStatusDataToDisplay.date), DateFormatWithTime[lang])
                : NO_DATA,
        country: registrationCountry ?? null,
        moneyTurnover: companyService?.moneyTurnover ?? 'not defined',
        moneyTurnoverCurrency: companyService?.currency.code ?? CURRENCY_INITIALS.code,
        isApplicationProfileCompleted,
        numberOfPersons: companyService?.numberOfPersons ?? 'not defined',
        numberOfCustomers: companyService?.numberOfCustomers ?? 'not defined',
        isAccountInAnotherBank: getYesNoResult(companyService?.isAccountInAnotherBank),
        isSubmitFinancialTaxReport: getYesNoResult(companyService?.isSubmitFinancialTaxReport),
        spendPlan: String(companyService?.spendPlan ?? 0),
        preferredAccountCurrency: companyService?.preferredAccountCurrency ?? 'not defined',
        industryDescription: industryDescription ?? 'not defined',
        isCompanyAddressSame: isCompanyAddressSame ?? postalAddress?.id == null,
        legalAddress: getNotDefinedAddress(legalAddress),
        postalAddress: getNotDefinedAddress(postalAddress),
        companySite,
        biggestClient,
        registrationDate,
        email: owner.email,
        phone: owner.phone,
        userId: owner.userId,
        termsAccepted: companyStatus?.termsAccepted ?? null,
    };

    return [initialData, handleAction];
};
