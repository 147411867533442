import styled from 'styled-components';

import { Flex } from '@shared-style/flex';

export const TemplateNotificationWrapper = styled.div`
    ${Flex.Column.Style};
    width: 587px;
    gap: 24px;
    padding: 24px 2px 0;
`;

export const TemplateNotificationButtonsWrapper = styled.div`
    ${Flex.Row.Style};
    gap: 16px;
`;
