import { useMutation } from '@apollo/client';
import { useFormikContext } from 'formik';
import { GraphQLError } from 'graphql';
import { useContext, useEffect } from 'react';

import { InformationModalContext } from '@shared-component/information-modal/information-modal.context';
import { InformationModalEnum } from '@shared-component/information-modal/information-modal.enum';
import { ModelContext } from '@shared-component/modal/modal-context';
import { ModalEnum } from '@shared-component/modal/modal.enum';
import { OtpEnum } from '@shared-component/otp/otp.options';
import { AdminConfirmChangePhone, DefaultAnswer, Mutation, MutationCheckPhoneCodeArgs } from '@shared-graphql';
import { getGraphQLErrorMessages } from '@shared-util/get-graphql-error-code';
import { isExist, isTrue } from '@shared-util/is-data';

import { ClientSummaryEditPhoneFormFieldsInterface } from '@page/common/client-summary/client-summary-edit-phone-form/client-summary-edit-phone-form-fields/client-summary-edit-phone-form-fields.interface';
import { ClientSummaryEditPhoneFormEnum } from '@page/common/client-summary/client-summary-edit-phone-form/client-summary-edit-phone-form.enum';
import { ClientSummaryEditPhoneFormInterface } from '@page/common/client-summary/client-summary-edit-phone-form/client-summary-edit-phone-form.interface';

export const useClientSummaryEditPhoneFormFields = ({
    adminStartChangPhoneError,
    isAdminStartChangPhoneLoading,
    onSubmit,
    setFormVisible,
    shouldOpenOtpModal,
    updateUserPhone,
    reset,
}: ClientSummaryEditPhoneFormFieldsInterface) => {
    const { onOpen: onAdminModalOpen, onClose: onAdminModalClose } = useContext(ModelContext);
    const { onOpen: onInformationModalOpen } = useContext(InformationModalContext);

    const {
        values: { phone, userId },
        setErrors,
    } = useFormikContext<Omit<ClientSummaryEditPhoneFormInterface, 'updateUserPhone'>>();

    const [
        adminConfirmChangePhone,
        {
            data: adminConfirmChangePhoneData,
            loading: isAdminConfirmChangePhoneLoading,
            error: adminConfirmChangePhoneError,
        },
    ] = useMutation<Pick<Mutation, 'adminConfirmChangePhone'>>(AdminConfirmChangePhone);

    const confirmChanging = (otp: Pick<MutationCheckPhoneCodeArgs, OtpEnum.Code>) => {
        adminConfirmChangePhone({ variables: { userId, otp } });
    };

    useEffect(() => {
        if (isTrue(shouldOpenOtpModal)) {
            onAdminModalOpen(ModalEnum.SendOTPModal, {
                componentProps: {
                    phone,
                    userId,
                    onSubmit: confirmChanging,
                    error: adminConfirmChangePhoneError,
                    onResendOtpCode: () => onSubmit({ phone, userId }),
                },
            });
        }
    }, [shouldOpenOtpModal]);

    useEffect(() => {
        if (isExist(adminStartChangPhoneError)) {
            setErrors({
                [ClientSummaryEditPhoneFormEnum.Phone]:
                    getGraphQLErrorMessages(adminStartChangPhoneError?.graphQLErrors as GraphQLError[])[0] ??
                    adminStartChangPhoneError?.message,
            });
        }
    }, [adminStartChangPhoneError]);

    useEffect(() => {
        if (isExist(adminConfirmChangePhoneError)) {
            onAdminModalOpen(ModalEnum.SendOTPModal, {
                componentProps: {
                    phone,
                    userId,
                    onSubmit: confirmChanging,
                    error:
                        getGraphQLErrorMessages(adminConfirmChangePhoneError?.graphQLErrors as GraphQLError[])[0] ??
                        adminConfirmChangePhoneError?.message,
                    onResendOtpCode: () => onSubmit({ phone, userId }),
                },
            });
        }
    }, [adminConfirmChangePhoneError]);

    useEffect(() => {
        if (isExist((adminConfirmChangePhoneData?.adminConfirmChangePhone as DefaultAnswer)?.status)) {
            if (isTrue((adminConfirmChangePhoneData?.adminConfirmChangePhone as DefaultAnswer)?.status)) {
                updateUserPhone(phone);
                setFormVisible(false);
                onAdminModalClose();
                reset();
                onInformationModalOpen(InformationModalEnum.SuccessInformationModal, {
                    text: 'Phone number successfully changed',
                });
            } else {
                setFormVisible(false);
                onInformationModalOpen(InformationModalEnum.FailureInformationModal, {
                    text:
                        getGraphQLErrorMessages(adminConfirmChangePhoneError?.graphQLErrors as GraphQLError[])[0] ??
                        adminConfirmChangePhoneError?.message ??
                        "Phone number change didn't save",
                });
            }
        }
    }, [adminConfirmChangePhoneData]);

    return { isLoading: isAdminStartChangPhoneLoading || isAdminConfirmChangePhoneLoading };
};
