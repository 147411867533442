import { useField } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { FC, FormEvent, RefObject, SVGProps, useState } from 'react';
import { validate as isValidUUID } from 'uuid';

import { Button } from '@shared-atom/button/button';
import { ButtonTypeEnum } from '@shared-atom/button/button-type.enum';
import { FieldError } from '@shared-component/field-error/field-error';
import { InputTagEnum } from '@shared-component/input/input-tag.enum';
import {
    AddValueButton,
    ButtonsWrapper,
    DeleteIcon,
    SingleValue,
    ValueListWrapper,
} from '@shared-component/input/multi-values-input/multi-values-input.styles';
import { OnEventType } from '@shared-type/on-event.type';
import { isExist, isFalse, isString, isTrue } from '@shared-util/is-data';

import { InputTypeEnum } from '../input-type.enum';
import { ErrorWrapper, IconWrapper, InputStyle, InputWrapper, Label, LabelWrapper } from '../input.styles';

export interface MultiValuesInputInterface extends Partial<Pick<HTMLInputElement, 'type' | 'tabIndex' | 'disabled'>> {
    title?: string;
    name: string;
    onFocus?: OnEventType<boolean>;
    icon?: FC<SVGProps<SVGSVGElement>>;
    inputRef?: RefObject<HTMLInputElement>;
    tag?: InputTagEnum;
    children?: React.ReactNode;
}

export const MultiValuesInput: FC<MultiValuesInputInterface> = observer(
    ({ title, name, type = InputTypeEnum.Text, icon, tag, inputRef, onFocus, disabled, children, ...props }) => {
        const [field, , { setValue }] = useField(`${name}-input`);
        const [{ value: valuesList }, { error }, { setError, setValue: setValuesList }] = useField(name);
        const [isFocused, setFocused] = useState(false);

        const hasValue = typeof field.value === 'string' ? isString(field.value) : isExist(field.value);
        const hasIcon = icon !== undefined;
        const hasError = error !== undefined;
        const shouldRenderLabel = !hasIcon && isString(title);

        const toggleFocus = (isFocus: boolean) => () => {
            setFocused(isFocus);
            if (onFocus instanceof Function) {
                onFocus(isFocus);
            }
        };

        const handleBlur = () => {
            toggleFocus(false)();
            if (type === InputTypeEnum.Text) {
                setValue(field.value?.trim() ?? null);
            }
        };

        const handleAddValueToArray = (value: string) => {
            if (isValidUUID(value)) {
                if (isTrue(valuesList.some((val: string) => value === val))) {
                    setError('Client ID is already added');
                } else {
                    setValuesList([...valuesList, value]);
                    setValue('');
                    if (hasError) {
                        setError(undefined);
                    }
                }
            } else {
                setError('Invalid Client ID');
            }
        };

        const handleDeleteValueFromArray = (value: string) => {
            setValuesList(valuesList.filter((val: string) => value !== val));
        };

        const handleChange = ({ currentTarget: { value } }: FormEvent<HTMLInputElement>) => {
            setValue(value.trim());
            if (hasError && tag !== InputTagEnum.TextArea) {
                setError(undefined);
            }
        };

        const Icon = icon !== undefined && IconWrapper(icon);
        const taggedInput = { as: tag } as unknown;
        return (
            <InputWrapper>
                <LabelWrapper hasError={hasError} isFocused={isFocused} isDisabled={disabled}>
                    {Icon !== false && <Icon hasValue={hasValue} />}
                    <InputStyle
                        {...props}
                        {...field}
                        {...(taggedInput as any)}
                        ref={inputRef}
                        type={type}
                        value={field.value ?? ''}
                        disabled={disabled}
                        hasIcon={hasIcon}
                        placeholder={hasIcon ? title : ''}
                        onFocus={toggleFocus(true)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                    />
                    {shouldRenderLabel && (
                        <Label hasValue={hasValue} isFocused={isFocused} hasError={hasError}>
                            {title}
                        </Label>
                    )}
                    <ButtonsWrapper>
                        {isTrue(field.value) && <DeleteIcon onClick={() => setValue('')} />}
                        <Button
                            Component={AddValueButton}
                            type={ButtonTypeEnum.Button}
                            onClick={() => handleAddValueToArray(field.value)}
                            disabled={isFalse(field.value)}
                        >
                            Add
                        </Button>
                    </ButtonsWrapper>
                </LabelWrapper>
                <ErrorWrapper>
                    <FieldError name={name} />
                </ErrorWrapper>
                {isTrue(valuesList) && valuesList.length > 0 && (
                    <ValueListWrapper>
                        {valuesList.map((singleValue: string) => (
                            <SingleValue key={singleValue}>
                                {singleValue}
                                <DeleteIcon onClick={() => handleDeleteValueFromArray(singleValue)} />
                            </SingleValue>
                        ))}
                    </ValueListWrapper>
                )}
            </InputWrapper>
        );
    }
);
