import { useMutation } from '@apollo/client';
import { GraphQLError } from 'graphql/index';
import { useContext, useEffect, useState } from 'react';

import { InformationModalContext } from '@shared-component/information-modal/information-modal.context';
import { InformationModalEnum } from '@shared-component/information-modal/information-modal.enum';
import { AdminCreateNotification, AdminCreateNotificationMutation } from '@shared-graphql';
import { useCurrentNavigation, useRouterNavigate } from '@shared-hook/navigation/use-current-navigation.hook';
import { getGraphQLErrorMessages } from '@shared-util/get-graphql-error-code';
import { isExist } from '@shared-util/is-data';

import { PageEnum } from '@enum/page.enum';
import { SendFlowEnum } from '@page/emails-and-notifications/components/send-flow-selector/send-flow-selector.enum';
import { EmailsAndNotificationsTableFilterFormEnum } from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications-table-filter/emails-and-notifications-table-filter-form/emails-and-notifications-table-filter-form.enum';
import { NotificationInstanceTypeEnum } from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications.enum';

export const useCreateNotificationPage = () => {
    const { params } = useCurrentNavigation();
    const { onOpen } = useContext(InformationModalContext);

    const navigate = useRouterNavigate();
    const [currentSendFlow, setCurrentSendFlow] = useState<SendFlowEnum>(SendFlowEnum.ByClientType);

    const [adminCreateNotification, { data, error, loading: isLoading }] =
        useMutation<AdminCreateNotificationMutation>(AdminCreateNotification);

    useEffect(() => {
        if (isExist(data?.adminCreateNotification)) {
            setTimeout(
                () =>
                    onOpen(InformationModalEnum.SuccessInformationModal, {
                        text: 'Your notification has been successfully created. Thank you for using our service!',
                    }),
                500
            );
            navigate(PageEnum.EmailsAndNotifications, {
                ...params,
                [EmailsAndNotificationsTableFilterFormEnum.NotificationInstanceType]:
                    NotificationInstanceTypeEnum.Notifications,
            });
        }
    }, [data]);

    useEffect(() => {
        if (isExist(error)) {
            onOpen(InformationModalEnum.FailureInformationModal, {
                text:
                    getGraphQLErrorMessages(error?.graphQLErrors as GraphQLError[])[0] ??
                    error?.message ??
                    'An error occurred',
            });
        }
    }, [data]);

    return {
        notificationId: params.id,
        currentSendFlow,
        setCurrentSendFlow,
        adminCreateNotification,
        isLoading,
    };
};
