import { rgba } from 'polished';
import styled from 'styled-components';

import { ButtonTransparentStyle } from '@shared-atom/button/button.style';
import { Typography } from '@shared-atom/typography/typography';
import { FieldErrorWrapper } from '@shared-component/field-error/field-error.styles';
import { Fiord, LinkWater, Raven, Red, DarkBlue, White, Mystic, Dark, WhiteDark } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';

export const Wrapper = styled.div``;

export const SelectWrapper = styled.div`
    display: inline-flex;
    position: relative;
    width: 100%;
`;

export const SelectRowItem = styled.div`
    width: 100%;
    min-height: 48px;
    padding: 16px;
    ${Flex.Row.JustifyLeft.VerticalCenter.Style}

    :hover {
        background-color: ${LinkWater};
    }
`;

export const containerStyle = (provided: any) => ({
    ...provided,
    width: '100%',
});
export const containerLangStyle = (provided: any) => ({
    ...provided,
    marginRight: '20px',
    marginLeft: '20px',
    width: '75px',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '@media (max-width: 1023px)': {
        width: '200px',
    },
});
export const menuStyle = (provided: any) => ({
    ...provided,
    color: ' #8C8C8C',
    borderRadius: '0px 0px 5px 5px',
});
export const controlStyle = (isMenuOpened: boolean, hasError: boolean, isDarkTheme: boolean) => (provided: any) => {
    const [boxShadowDefaultColor, backgroundColor] = isDarkTheme ? [WhiteDark, DarkBlue] : [Mystic, White];
    return {
        ...provided,
        border: 'none',
        display: 'flex',
        height: 56,
        borderRadius: isMenuOpened ? '8px 8px 0 0' : '8px',
        boxShadow: `0 0 0 1px ${hasError ? Red : boxShadowDefaultColor}`,
        backgroundColor,
        cursor: 'text',
    };
};
export const controlLangStyle = (provided: any) => ({
    ...provided,
    cursor: 'pointer',
    width: '75px',
    outline: 'none',
    border: 0,
    boxShadow: 'none',
    backgroundColor: 'unset',
    borderColor: 'unset',
    justifyContent: 'start',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '@media (max-width: 1023px)': {
        width: '200px',
    },
});

export const indicatorSeparatorStyle = (isDarkTheme: boolean) => (provided: any) => ({
    ...provided,
    backgroundColor: isDarkTheme ? WhiteDark : Mystic,
});
export const withoutIndicatorSeparatorStyle = (provided: any) => ({
    ...provided,
    display: 'none',
});

export const indicatorsContainerStyle = (provided: any) => ({
    ...provided,
    cursor: 'pointer',
});
export const dropdownIndicatorStyle = (provided: any) => ({
    ...provided,
    padding: '0px',
});
export const indicatorsLangContainerStyle = (provided: any) => ({
    ...provided,
    display: 'none',
});

export const SelectRowWrapperStyle = styled(ButtonTransparentStyle)`
    justify-content: left;
`;

export const selectMenuStyle = (isDarkTheme: boolean) => (provided: any) => ({
    ...provided,
    boxShadow: `0 10px 10px ${rgba(Fiord, 0.12)}`,
    margin: 0,
    borderRadius: '0 0 8px 8px',
    padding: '8px 0',
    width: '100%',
    backgroundColor: isDarkTheme ? Mystic : White,
});

export const singleValueStyle = (isDarkTheme: boolean) => (provided: any) => ({
    ...provided,
    color: isDarkTheme ? White : Dark,
    padding: '12px',
    textTransform: 'uppercase',
});

export const placeholderStyle = (provided: any) => ({
    ...provided,
    position: 'absolute',
    color: Raven,
    fontSize: 16,
    left: 16,
    top: '50%',
    transform: 'translateY(-50%)',
});

export const valueContainerStyle = (provided: any) => ({
    ...provided,
    display: 'inline-flex',
    padding: 0,
    flex: 1,
});
export const valueContainerLangStyle = (provided: any) => ({
    ...provided,
    justifyContent: 'center',
    maxWidth: '30px',
    padding: '2px 0px',
});

export const inputStyle = (isDarkTheme: boolean) => (provided: any) => ({
    ...provided,
    padding: 16,
    color: isDarkTheme ? White : Dark,
});
export const inputLangStyle = (provided: any) => ({
    ...provided,
    padding: '0 10px',
});

export const noOptionsMessageStyle = (provided: any) => ({
    ...provided,
    ...Typography.S.Normal.Object,
});

export const ErrorWrapper = styled(FieldErrorWrapper)``;
