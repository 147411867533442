import { useMemo, useState } from 'react';

import { NotificationInstanceTypeEnum } from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications.enum';

export const useNotificationInstanceTypeFilter = (
    initialNotificationInstanceType: NotificationInstanceTypeEnum | string
) => {
    const [notificationInstanceType, setNotificationInstanceType] = useState<NotificationInstanceTypeEnum | string>(
        initialNotificationInstanceType
    );

    const onNotificationInstanceTypeChange = (newNotificationInstanceType: NotificationInstanceTypeEnum) =>
        setNotificationInstanceType(newNotificationInstanceType);

    return useMemo(
        () => [{ notificationInstanceType }, { onNotificationInstanceTypeChange }, {}],
        [notificationInstanceType]
    );
};
