import { $enum } from 'ts-enum-util';

import { BaseOptionInterface } from '@shared-component/select/select.props';
import { TemplateUserStatus, TemplateUserType } from '@shared-graphql';

import { EmailsAndNotificationsEnum } from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications.enum';

const userStatusOptions = $enum(TemplateUserStatus).map(value => ({
    value,
    label: value,
}));
const userTypeOptions = $enum(TemplateUserType).map(value => ({
    value,
    label: value,
}));

export const clientStatusOptions = [
    { value: null, label: EmailsAndNotificationsEnum.All },
    ...userStatusOptions,
] as BaseOptionInterface[];

export const clientTypeOptions = [
    { value: null, label: EmailsAndNotificationsEnum.All },
    ...userTypeOptions,
] as BaseOptionInterface[];
