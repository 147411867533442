import { format } from 'date-fns';
import React from 'react';

import { Tooltip } from '@shared-atom/tooltip/tooltip';
import { CellHoveredText } from '@shared-component/table/table-cell/table-cell.styles';
import { Notification, Template } from '@shared-graphql';

export const DateCell = ({ updatedAt }: Template | Notification) => {
    const date = format(new Date(updatedAt), 'dd.MM.yyyy HH:mm');

    return (
        <CellHoveredText>
            <Tooltip content={`${date}`} dataFor={`tooltip-for-template-last-date-${date}`}>
                {date}
            </Tooltip>
        </CellHoveredText>
    );
};
