import { FC } from 'react';

import { Tooltip } from '@shared-atom/tooltip/tooltip';
import { DateRangeFilter } from '@shared-component/table/table-filters/date-range-filter/date-range-filter';
import { SelectFilter } from '@shared-component/table/table-filters/select-filter/select-filter';
import {
    SelectPreview,
    SelectPreviewData,
    SelectPreviewTitle,
} from '@shared-component/table/table-filters/select-filter/select-filter.styles';
import { TableFiltersFormWrapper } from '@shared-component/table/table.styles';

import { ClientsTableFilterFormEnum } from '@page/clients/clients-table/clients-table-filter/clients-table-filter-form/clients-table-filter-form.enum';
import { EmailsAndNotificationsTableFilterFormEnum } from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications-table-filter/emails-and-notifications-table-filter-form/emails-and-notifications-table-filter-form.enum';
import { useEmailAndNotificationTableFilterForm } from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications-table-filter/emails-and-notifications-table-filter-form/emails-and-notifications-table-filter-form.hook';
import { FilterFormInterface } from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications-table-filter/emails-and-notifications-table-filter-form/emails-and-notifications-table-filter-form.interface';
import {
    clientStatusOptions,
    clientTypeOptions,
} from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications-table-filter/emails-and-notifications-table-filter-form/emails-and-notifications-table-filter-form.options';

export const EmailsAndNotificationsTableFilterForm: FC<FilterFormInterface> = ({
    onClientStatusChange,
    onClientTypeChange,
    onCreatedFromChange,
    onCreatedToChange,
    instancesCount,
}) => {
    useEmailAndNotificationTableFilterForm({
        onClientStatusChange,
        onClientTypeChange,
        onCreatedFromChange,
        onCreatedToChange,
    });

    return (
        <TableFiltersFormWrapper>
            <SelectPreview>
                <SelectPreviewTitle>Found:</SelectPreviewTitle>
                <SelectPreviewData clickable={false}>{instancesCount ?? 0}</SelectPreviewData>
            </SelectPreview>
            <Tooltip content="Coming soon" dataFor="tooltip-copy-for-date-range-filter">
                <DateRangeFilter
                    nameFrom={ClientsTableFilterFormEnum.CreatedFrom}
                    nameTo={ClientsTableFilterFormEnum.CreatedTo}
                    title="Date of creation"
                    isDisabled
                />
            </Tooltip>
            <Tooltip content="Coming soon" dataFor="tooltip-copy-for-status-filter">
                <SelectFilter
                    name={EmailsAndNotificationsTableFilterFormEnum.ClientStatus}
                    title="Status"
                    options={clientStatusOptions}
                    isDisabled
                />
            </Tooltip>
            <Tooltip content="Coming soon" dataFor="tooltip-copy-for-client-type-filter">
                <SelectFilter
                    name={EmailsAndNotificationsTableFilterFormEnum.ClientType}
                    title="Client type"
                    options={clientTypeOptions}
                    isDisabled
                />
            </Tooltip>
        </TableFiltersFormWrapper>
    );
};
