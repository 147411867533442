import { ReactNode } from 'react';

import { TableRowInterface } from '@shared-component/table/table-body/table-row/table-row';
import { Cell } from '@shared-component/table/table-cell/table-cell';
import { Notification, Template } from '@shared-graphql';

import { templateOrNotificationRowOptions } from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications-table-row/template-or-notification-row/template-or-notification-row.options';

export const EmailsAndNotificationsTableRow = ({ id }: TableRowInterface<Template | Notification>) =>
    templateOrNotificationRowOptions.map((item: ReactNode) => <Cell key={`${id}-${item?.toString()}`}>{item}</Cell>);
