import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Flex } from '@shared-style/flex';

export const ConfirmationText = styled.h3`
    ${Typography.Paragraph18.Neutral700.Style}
`;

export const ButtonSubmitWrapper = styled.div`
    margin-top: 32px;
    ${Flex.Row.Style};
    gap: 16px;
`;
