import { useField } from 'formik';
import { useEffect } from 'react';

import { useCurrentNavigation, useRouterNavigate } from '@shared-hook/navigation/use-current-navigation.hook';
import { getDate } from '@shared-util/get-date';
import { isTrue } from '@shared-util/is-data';

import { PageEnum } from '@enum/page.enum';
import { EmailsAndNotificationsTableFilterFormEnum } from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications-table-filter/emails-and-notifications-table-filter-form/emails-and-notifications-table-filter-form.enum';
import { FilterFormInterface } from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications-table-filter/emails-and-notifications-table-filter-form/emails-and-notifications-table-filter-form.interface';
import { EmailsAndNotificationsEnum } from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications.enum';

export const useEmailAndNotificationTableFilterForm = ({
    onClientStatusChange,
    onClientTypeChange,
    onCreatedFromChange,
    onCreatedToChange,
}: Omit<FilterFormInterface, 'instancesCount'>) => {
    const navigate = useRouterNavigate();
    const { searchParams: params } = useCurrentNavigation();

    const { clientType, clientStatus, createdFrom, createdTo } = params;

    const [{ value: clientStatusValue }] = useField(EmailsAndNotificationsTableFilterFormEnum.ClientStatus);
    const [{ value: clientTypeValue }] = useField(EmailsAndNotificationsTableFilterFormEnum.ClientType);
    const [{ value: createdFromValue }] = useField(EmailsAndNotificationsTableFilterFormEnum.CreatedFrom);
    const [{ value: createdToValue }] = useField(EmailsAndNotificationsTableFilterFormEnum.CreatedTo);

    useEffect(
        () =>
            void (
                clientStatusValue !== undefined &&
                navigate(PageEnum.EmailsAndNotifications, {
                    ...params,
                    clientStatus: clientStatusValue === null ? EmailsAndNotificationsEnum.All : clientStatusValue,
                })
            ),
        [clientStatusValue]
    );

    useEffect(
        () =>
            void (
                clientTypeValue !== undefined &&
                navigate(PageEnum.EmailsAndNotifications, {
                    ...params,
                    clientType: clientTypeValue === null ? EmailsAndNotificationsEnum.All : clientTypeValue,
                })
            ),
        [clientTypeValue]
    );

    useEffect(() => {
        if (createdFromValue !== undefined) {
            navigate(PageEnum.EmailsAndNotifications, {
                ...params,
                createdFrom: isTrue(createdFromValue) ? getDate(createdFromValue, 'dd-MM-yyyy') : '',
            });
        }
    }, [createdFromValue]);

    useEffect(() => {
        if (createdToValue !== undefined) {
            navigate(PageEnum.EmailsAndNotifications, {
                ...params,
                createdTo: isTrue(createdToValue) ? getDate(createdToValue, 'dd-MM-yyyy') : '',
            });
        }
    }, [createdToValue]);

    useEffect(
        () =>
            void onClientStatusChange(
                clientStatus !== undefined && clientStatus !== EmailsAndNotificationsEnum.All
                    ? clientStatus
                    : EmailsAndNotificationsEnum.All
            ),
        [clientStatus]
    );
    useEffect(
        () =>
            void onClientTypeChange(
                clientType !== undefined && clientType !== EmailsAndNotificationsEnum.All ? clientType : ''
            ),
        [clientType]
    );
    useEffect(() => {
        void onCreatedFromChange(createdFrom !== undefined && isTrue(createdFrom) ? createdFrom : '');
    }, [createdFrom]);

    useEffect(() => {
        void onCreatedToChange(createdTo !== undefined && isTrue(createdTo) ? createdTo : '');
    }, [createdTo]);
};
