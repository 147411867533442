import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';

import { TableComponent } from '@shared-component/table/table';
import { TableWrapper } from '@shared-component/table/table.styles';
import { noop } from '@shared-util/noop';

import { EmailsAndNotificationsTableFilter } from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications-table-filter/emails-and-notifications-table-filter';
import { EmailsAndNotificationsTableHeader } from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications-table-header/emails-and-notifications-table-header';
import { EmailsAndNotificationsTableRow } from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications-table-row/emails-and-notifications-table-row';
import { useEmailsAndNotifications } from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications.hook';
import { EmailsAndNotificationsOptions } from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications.options';
import { EmptyTableComponent } from '@page/emails-and-notifications/emails-and-notifications-table/empty-table-component/empty-table-component';

export const EmailsAndNotifications = observer(() => {
    const {
        instanceList,
        filters,
        isLoading,
        pageSize,
        currentPage,
        onPageChange,
        onSearch,
        // onClientStatusChange,
        // onClientTypeChange,
        // onCreatedFromChange,
        // onCreatedToChange,
        tableRef,
        onResetFilters,
        totalInstancesCount,
        limitRowsPerPage,
        onNotificationInstanceTypeChange,
    } = useEmailsAndNotifications();

    const Row = EmailsAndNotificationsTableRow as unknown as ReactNode;

    return (
        <>
            <EmailsAndNotificationsTableHeader onSearchChange={onSearch} filters={filters} />
            <EmailsAndNotificationsTableFilter
                instancesCount={instanceList.length}
                filters={filters}
                onNotificationInstanceTypeChange={onNotificationInstanceTypeChange}
                onResetFilters={onResetFilters}
                onClientStatusChange={noop}
                onClientTypeChange={noop}
                onCreatedFromChange={noop}
                onCreatedToChange={noop}
            />
            <TableWrapper>
                <TableComponent
                    data={instanceList as any}
                    totalData={totalInstancesCount}
                    limitRowsPerPage={limitRowsPerPage}
                    columns={EmailsAndNotificationsOptions}
                    isDataLoading={isLoading}
                    pageCount={pageSize}
                    pageIndex={currentPage}
                    onPageChange={onPageChange}
                    tableRef={tableRef}
                    hasPagination
                    hasTableHead
                    hasInnerScroll
                    emptyTable={<EmptyTableComponent />}
                >
                    {Row}
                </TableComponent>
            </TableWrapper>
        </>
    );
});
