import { Link } from '@shared-atom/link/link';
import { Notification, Template } from '@shared-graphql';
import { useCurrentNavigation } from '@shared-hook/navigation/use-current-navigation.hook';
import { getPathnameWithParams } from '@shared-util/get-pathname-with-params';
import { isString } from '@shared-util/is-data';

import { PageEnum } from '@enum/page.enum';
import { NotificationInstanceTypeEnum } from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications.enum';

import { ActionsWrapper, IconWrapper, InfoIcon } from './actions-cell.styles';

export const ActionsCell = ({ id }: Template | Notification) => {
    const shouldRenderLink = isString(id);
    const { searchParams } = useCurrentNavigation();

    const { notificationInstanceType } = searchParams;

    return (
        <ActionsWrapper>
            {shouldRenderLink && (
                <>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <Link
                        to={{
                            pathname: getPathnameWithParams(
                                notificationInstanceType === NotificationInstanceTypeEnum.Notifications
                                    ? PageEnum.NotificationView
                                    : PageEnum.EmailView,
                                { id }
                            ),
                        }}
                    >
                        Details
                    </Link>
                </>
            )}
            {!shouldRenderLink && (
                <IconWrapper>
                    <InfoIcon />
                </IconWrapper>
            )}
        </ActionsWrapper>
    );
};
