export enum ModalEnum {
    Test,
    RegistrationLegalPrivatePersonModal,
    RegistrationLegalPersonLegalModal,
    PaymentModal,
    RequestStatementModal,
    NewWithdrawalModal,
    CreatePartnerModal,
    UserProfileModal,
    CurrencyExchangeModal,
    DeletePartnerConfirmationModal,
    AddCurrency,
    AccountDetails,
    // EditporsonalData
    PrivateRequestStatementModal,
    CreateIFXCredentialsModal,
    RejectPaymentModal,
    AddServiceModal,
    AddServiceRequiredFieldsModal,
    SendOTPModal,
    ConfirmationModal,
}
