import { PageEnum } from '@enum/page.enum';

export const getBreadcrumbList = (notificationId: string) => [
    {
        path: PageEnum.EmailsAndNotifications,
        name: 'Emails & Notifications',
    },
    {
        name: `Notification #${notificationId}`,
    },
];
