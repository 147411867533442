import { useContext } from 'react';

import { Tooltip } from '@shared-atom/tooltip/tooltip';
import { InformationModalContext } from '@shared-component/information-modal/information-modal.context';
import { InformationModalEnum } from '@shared-component/information-modal/information-modal.enum';
import { Notification, Template } from '@shared-graphql';

import {
    IDCellContent,
    IDCellWrapper,
} from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications-table-row/template-or-notification-row/cells/id-cell/id-cell.styles';

export const IDCell = ({ id }: Template | Notification) => {
    const { onOpen } = useContext(InformationModalContext);
    const onDataClick = (data: string) => {
        navigator.clipboard.writeText(data);
        onOpen(InformationModalEnum.CopyInformationModal);
    };

    return (
        <IDCellWrapper>
            <Tooltip content={`${id}`} dataFor={`tooltip-for-template-id-${id}`}>
                <IDCellContent onClick={() => onDataClick(id)}>{id}</IDCellContent>
            </Tooltip>
        </IDCellWrapper>
    );
};
