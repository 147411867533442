import { Formik } from 'formik';
import { FC } from 'react';

import { LinkButtonPrimary } from '@shared-atom/link/link-button-primary';
import { CustomInputSearch } from '@shared-component/input/input-search/custom-input-search';
import { TableTitle, TableHeaderWrapper, TableSearchWrapper } from '@shared-component/table/table.styles';
import { noop } from '@shared-util/noop';

import { PageEnum } from '@enum/page.enum';
import { ClientFilterInterface } from '@page/clients/clients-table/clients-table-filter/clients-table-filter.interface';

export const ClientsHeader: FC<Pick<ClientFilterInterface, 'onSearchChange' | 'filters'>> = ({
    filters: { search },
    onSearchChange,
}) => (
    <TableHeaderWrapper>
        <TableTitle>Clients</TableTitle>
        <TableSearchWrapper>
            <Formik initialValues={{ search }} onSubmit={noop} enableReinitialize>
                <CustomInputSearch placeholder="Search by email or ID" onChange={onSearchChange} isSpacesDisabled />
            </Formik>
        </TableSearchWrapper>
        <LinkButtonPrimary to={{ pathname: PageEnum.CreateClient }}>Create client</LinkButtonPrimary>
    </TableHeaderWrapper>
);
