import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { PrimeBlue } from '@shared-style/colors';
import { Row } from '@shared-style/common.styles';
import { IsActiveType } from '@shared-type/common.type';

export const FilterByNotificationInstanceTypeContainer = styled(Row)`
    gap: 24px;
`;

export const FilterByNotificationInstanceTypeButton = styled(Row)<IsActiveType>`
    padding: 16px 0;
    cursor: pointer;
    position: relative;
    ${Typography.Paragraph14.SemiBold.Neutral500.Style};
    ${({ isActive }) =>
        isActive &&
        `
        ${Typography.Paragraph14.SemiBold.PrimeBlue.Style};
         &::after {
            content: '';
            width: 100%;
            height: 1px;
            background-color: ${PrimeBlue};
            position: absolute;
            left: 0;
            bottom: 0;
        }
    `}
`;
