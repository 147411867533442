import { Formik } from 'formik';
import { FC } from 'react';

import { ButtonPrimaryStyle } from '@shared-atom/button/button.style';
import { Tooltip } from '@shared-atom/tooltip/tooltip';
import { CustomInputSearch } from '@shared-component/input/input-search/custom-input-search';
import { TableTitle, TableHeaderWrapper, TableSearchWrapper } from '@shared-component/table/table.styles';
import { OnEventType } from '@shared-type/on-event.type';
import { noop } from '@shared-util/noop';

interface TransactionsHeaderInterface {
    onSearchChange: OnEventType<string>;
    filters: Record<string, any>;
}

export const TransactionsHeader: FC<TransactionsHeaderInterface> = ({ filters: { search }, onSearchChange }) => (
    <TableHeaderWrapper>
        <TableTitle>Payments</TableTitle>
        <TableSearchWrapper>
            <Formik initialValues={{ search }} onSubmit={noop} enableReinitialize>
                <CustomInputSearch placeholder="Search by payment ID" onChange={onSearchChange} />
            </Formik>
        </TableSearchWrapper>
        <Tooltip content="Will be available soon" dataFor="export–to-csv-button-tooltip">
            <ButtonPrimaryStyle>Export to CSV</ButtonPrimaryStyle>
        </Tooltip>
    </TableHeaderWrapper>
);
