import { observer } from 'mobx-react-lite';

import { TemplateNotificationForm } from '@shared-form/template-notification-form/template-notification-form';
import { isString } from '@shared-util/is-data';

import { Breadcrumbs } from '@component/breadcrumbs/breadcrumbs';
import { BreadCrumbsWrapper, HeaderWrapper, PageTitle } from '@page/common/common.styles';
import { SendFlowSelector } from '@page/emails-and-notifications/components/send-flow-selector/send-flow-selector';
import {
    NotificationInstancePageContent,
    NotificationInstancePageDescription,
} from '@page/emails-and-notifications/emails-and-notifications.page.styles';
import { useCreateNotificationPage } from '@page/emails-and-notifications/notification/create/create-notification.hook';
import { breadcrumbList } from '@page/emails-and-notifications/notification/create/create-notification.options';

export const CreateNotificationPage = observer(() => {
    const { notificationId, currentSendFlow, setCurrentSendFlow, adminCreateNotification, isLoading } =
        useCreateNotificationPage();

    return (
        <>
            <BreadCrumbsWrapper>
                <Breadcrumbs breadcrumbs={breadcrumbList} />
            </BreadCrumbsWrapper>
            <HeaderWrapper>
                <PageTitle>Notification {isString(notificationId) ? `#${notificationId}` : ''}</PageTitle>
            </HeaderWrapper>
            <NotificationInstancePageContent>
                <NotificationInstancePageDescription>
                    Notification will be sent to next users:
                </NotificationInstancePageDescription>
                <SendFlowSelector currentSendFlow={currentSendFlow} setCurrentSendFlow={setCurrentSendFlow} />
                <TemplateNotificationForm
                    currentSendFlow={currentSendFlow}
                    isLoading={isLoading}
                    onCreate={adminCreateNotification}
                />
            </NotificationInstancePageContent>
        </>
    );
});
