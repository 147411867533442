import { Input } from '@shared-component/input/input';
import { MagnifierIcon } from '@shared-component/input/input-search/input-search.styles';
import { MultiValuesInput } from '@shared-component/input/multi-values-input/multi-values-input';
import { TextArea } from '@shared-component/input/textarea/textarea';
import { FormRow } from '@shared-form/form-common/form-common.styles';
import { TemplateNotificationFormEnum } from '@shared-form/template-notification-form/template-notification-form.enum';

export const TemplateNotificationFormByClientIdsFields = () => (
    <>
        <FormRow>
            <MultiValuesInput name={TemplateNotificationFormEnum.ClientIds} title="Recipient ID" icon={MagnifierIcon} />
        </FormRow>
        <Input name={TemplateNotificationFormEnum.Subject} title="Subject" />
        <TextArea title="Notification text" name={TemplateNotificationFormEnum.Template} maxLength={200} />
    </>
);
