import { Tooltip } from '@shared-atom/tooltip/tooltip';
import { CellText } from '@shared-component/table/table-cell/table-cell.styles';
import { Notification, Template } from '@shared-graphql';

import { RecipientsCellTooltipItem } from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications-table-row/template-or-notification-row/cells/recipients-cell/recipients-cell.styles';

export const RecipientsCell = ({ clients }: Template | Notification) => {
    if (clients.length === 0) {
        return <CellText>-</CellText>;
    }
    if (clients.length === 1) {
        return <CellText>{clients[0].name}</CellText>;
    }

    const content: any = clients.map(({ name }) => (
        <RecipientsCellTooltipItem key={name}>{name}</RecipientsCellTooltipItem>
    ));

    return (
        <Tooltip content={content} dataFor="tooltip-for-list-of-clients">
            {`${clients.length} recipients`}
        </Tooltip>
    );
};
