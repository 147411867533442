import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import { ButtonPrimary } from '@shared-atom/button/button-primary';
import { ButtonTypeEnum } from '@shared-atom/button/button-type.enum';
import { Input } from '@shared-component/input/input';
import { InputAmount } from '@shared-component/input/input-amount/input-amount';
import { Select } from '@shared-component/select/select';
import { CurrenciesEnum } from '@shared-enum/currencies.enum';
import { useLocalizationStore } from '@shared-hook/localization/use-localization-store.hook';

import { CommissionProfileInfoAdministrationList } from '@page/commission-profile/commission-profile-info/commission-profile-info-administration/commission-profile-info-administration-list/commission-profile-info-administration-list';
import {
    administrationList,
    CommissionListAdministrationProps,
} from '@page/commission-profile/commission-profile-info/commission-profile-info.options';
import { Title } from '@page/commission-profile/commission-profile-info/commission-profile-info.styles';

import { legalClientAdministrationButton } from './legal-client-administration-button/legal-client-administration-button';
import { useLegalClientAdministration } from './legal-client-administration.hook';
import { initialLegalClientAdministration } from './legal-client-administration.initial';
import { FormWrapper, TitleWrapper, Wrapper } from './legal-client-administration.styles';
import { getClientAdministrationOptions } from './legal-client-administration.util';

export const LegalClientAdministration = observer(() => {
    const [accountAdministration, ccAccounts, onSubmit] = useLegalClientAdministration();
    const { keys } = useLocalizationStore();
    const [initialState, setInitialState] = useState(initialLegalClientAdministration);

    const handlePay = (item: CommissionListAdministrationProps) => () => {
        const account = ccAccounts.find(current => current.currency.code === CurrenciesEnum.EUR);

        setInitialState(state => ({
            ...state,
            amount: accountAdministration[item.id] as number,
            reason: keys[item.title],
            accountId: account?.id,
        }));
    };

    const accounts = getClientAdministrationOptions(ccAccounts);

    return (
        <>
            <TitleWrapper>
                <Title>Account administration</Title>
                <Title>Create payment</Title>
            </TitleWrapper>
            <Wrapper>
                <CommissionProfileInfoAdministrationList
                    list={administrationList}
                    accountAdministration={accountAdministration}
                >
                    {legalClientAdministrationButton(handlePay)}
                </CommissionProfileInfoAdministrationList>
                <Formik
                    initialValues={initialState}
                    onSubmit={onSubmit}
                    validateOnBlur={false}
                    validateOnChange={false}
                    enableReinitialize
                >
                    <FormWrapper>
                        <Select name="accountId" title="Account currency" options={accounts} />
                        <InputAmount name="amount" title="Amount" />
                        <Input name="reason" title="Reason" />
                        <ButtonPrimary type={ButtonTypeEnum.Submit}>Create payment</ButtonPrimary>
                    </FormWrapper>
                </Formik>
            </Wrapper>
        </>
    );
});
