import { CustomInput } from '@shared-component/input/custom-input';
import { InputTypeEnum } from '@shared-component/input/input-type.enum';

import { useClientSummaryEditPhoneFormFields } from '@page/common/client-summary/client-summary-edit-phone-form/client-summary-edit-phone-form-fields/client-summary-edit-phone-form-fields.hook';
import { ClientSummaryEditPhoneFormFieldsInterface } from '@page/common/client-summary/client-summary-edit-phone-form/client-summary-edit-phone-form-fields/client-summary-edit-phone-form-fields.interface';
import { ClientSummaryEditPhoneFormEnum } from '@page/common/client-summary/client-summary-edit-phone-form/client-summary-edit-phone-form.enum';

export const ClientSummaryEditPhoneFormFields = ({
    adminStartChangPhoneError,
    isAdminStartChangPhoneLoading,
    onSubmit,
    setFormVisible,
    shouldOpenOtpModal,
    updateUserPhone,
    reset,
}: ClientSummaryEditPhoneFormFieldsInterface) => {
    const { isLoading } = useClientSummaryEditPhoneFormFields({
        adminStartChangPhoneError,
        isAdminStartChangPhoneLoading,
        onSubmit,
        setFormVisible,
        shouldOpenOtpModal,
        updateUserPhone,
        reset,
    });

    return <CustomInput name={ClientSummaryEditPhoneFormEnum.Phone} type={InputTypeEnum.Phone} disabled={isLoading} />;
};
