import { createBrowserRouter } from 'react-router-dom';

import { PageWrapper } from '@shared-component/page-wrapper/page-wrapper';
import { UserDocumentStatus } from '@shared-graphql';

import { PrivateClientProfileDocuments } from '@component/private-client-profile-documents/private-client-profile-documents';
import { PageEnum } from '@enum/page.enum';
import { CardsPage } from '@page/cards/cards.page';
import { ClientsPage } from '@page/clients/clients.page';
import { CommissionProfilePage } from '@page/commission-profile/commission-profile.page';
import { CreateClientPage } from '@page/create-client/create-client.page';
import { CurrenciesPage } from '@page/currencies/currencies.page';
import { EmailsAndNotificationsPage } from '@page/emails-and-notifications/emails-and-notifications.page';
import { CreateNotificationPage } from '@page/emails-and-notifications/notification/create/create-notification';
import { ViewNotificationPage } from '@page/emails-and-notifications/notification/view/view-notification';
import { LegalClientCreatePage } from '@page/legal-client-create/legal-client-create.page';
import { LegalClientProfileAddressPage } from '@page/legal-client-profile/legal-client-profile-address/legal-client-profile-address.page';
import { LegalClientProfileDocumentsPage } from '@page/legal-client-profile/legal-client-profile-documents/legal-client-profile-documents.page';
import { LegalClientProfileInformation } from '@page/legal-client-profile/legal-client-profile-information/legal-client-profile-information';
import { RegistrationLegalPersonsPage } from '@page/legal-client-profile/legal-client-profile-persons/registration-legal-persons.page';
import { LegalClientProfileService } from '@page/legal-client-profile/legal-client-profile-services/registration-legal-service.page';
import { LegalClientProfileShareholdersPage } from '@page/legal-client-profile/legal-client-profile-shareholders/legal-client-profile-shareholders.page';
import { LegalClientProfilePage } from '@page/legal-client-profile/legal-client-profile.page';
import { LegalClientAccounts } from '@page/legal-client/legal-client-accounts/legal-client-accounts';
import { LegalClientAdministration } from '@page/legal-client/legal-client-administration/legal-client-administration';
import { LegalClientDocuments } from '@page/legal-client/legal-client-documents/legal-client-documents';
import { LegalClientPricing } from '@page/legal-client/legal-client-pricing/legal-client-pricing';
import { LegalClientServices } from '@page/legal-client/legal-client-services/legal-client-services';
import { LegalClientSummary } from '@page/legal-client/legal-client-summary/legal-client-summary';
import { LegalClientPage } from '@page/legal-client/legal-client.page';
import { LoginPage } from '@page/login/login.page';
import { PageMenuList } from '@page/page-menu.list';
import { PaymentDocuments } from '@page/payment/payment-documents/payment-documents';
import { PaymentEdit } from '@page/payment/payment-edit/payment-edit';
import { PaymentSummary } from '@page/payment/payment-summary/payment-summary';
import { PaymentPage } from '@page/payment/payment.page';
import { PrivateClientProfileAcceptAgreement } from '@page/private-client-profile/private-client-profile-accept-agreement/private-client-profile-accept-agreement';
import { PrivateClientProfileAddress } from '@page/private-client-profile/private-client-profile-address/private-client-profile-address';
import { PrivateClientProfileDeclarations } from '@page/private-client-profile/private-client-profile-declarations/private-client-profile-declarations';
import { PrivateClientProfilePersonal } from '@page/private-client-profile/private-client-profile-personal/private-client-profile-personal';
import { PrivateClientProfileServices } from '@page/private-client-profile/private-client-profile-services/private-client-profile-services';
import { PrivateClientProfilePage } from '@page/private-client-profile/private-client-profile.page';
import { PrivateClientAccounts } from '@page/private-client/private-client-accounts/private-client-accounts';
import { PrivateClientComments } from '@page/private-client/private-client-comments/private-client-comments';
import { PrivateClientDocuments } from '@page/private-client/private-client-documents/private-client-documents';
import { PrivateClientDocumentArchiveTable } from '@page/private-client/private-client-documents/private-client-documents-archive-table/private-client-document-archive-table';
import { PrivateClientPricing } from '@page/private-client/private-client-pricing/private-client-pricing';
import { PrivateClientServices } from '@page/private-client/private-client-services/private-client-services';
import { PrivateClientSummary } from '@page/private-client/private-client-summary/private-client-summary';
import { PrivateClientPage } from '@page/private-client/private-client.page';
import { TransactionsPage } from '@page/transactions/transactions.page';
import { rootStore } from '@store/root-store';

export const routes = createBrowserRouter([
    {
        path: PageEnum.StatusClients,
        element: <PageWrapper pageMenuList={PageMenuList} />,
        children: [
            {
                index: true,
                element: <ClientsPage />,
            },
            {
                path: PageEnum.AdminCards,
                element: <CardsPage />,
            },
            {
                path: PageEnum.Currencies,
                element: <CurrenciesPage />,
            },
            {
                path: PageEnum.CreateClient,
                element: <CreateClientPage />,
            },
            {
                path: PageEnum.LegalClientCreate,
                element: <LegalClientCreatePage />,
                loader: () => {
                    rootStore.company.company.clearCompany();
                    return null;
                },
            },
            {
                path: PageEnum.Transactions,
                element: <TransactionsPage />,
            },
            {
                path: PageEnum.CommissionProfile,
                element: <CommissionProfilePage />,
            },
            {
                path: PageEnum.EmailsAndNotifications,
                element: <EmailsAndNotificationsPage />,
            },
            {
                path: PageEnum.NotificationCreate,
                element: <CreateNotificationPage />,
            },
            {
                path: PageEnum.NotificationView,
                element: <ViewNotificationPage />,
            },
            {
                path: PageEnum.PrivateClient,
                element: <PrivateClientPage />,
                loader: ({ params }) => {
                    rootStore.privateClient.loadClient(params.userId as string);
                    return null;
                },
                children: [
                    {
                        path: PageEnum.PrivateClientSummary,
                        element: <PrivateClientSummary />,
                        loader: ({ params }) => {
                            rootStore.account.account.loadClientAccounts(params.userId as string);
                            return null;
                        },
                    },
                    {
                        path: PageEnum.PrivateClientDocuments,
                        element: <PrivateClientDocuments />,
                        loader: ({ params }) => {
                            rootStore.privateClient.client.loadPrivateClientDocuments(params.userId as string);
                            return null;
                        },
                    },
                    {
                        path: PageEnum.PrivateClientArchivedDocuments,
                        element: <PrivateClientDocumentArchiveTable />,
                        loader: ({ params }) => {
                            rootStore.privateClient.client.loadPrivateClientDocuments(params.userId as string, [
                                UserDocumentStatus.Deleted,
                            ]);
                            return null;
                        },
                    },
                    {
                        path: PageEnum.PrivateClientAccounts,
                        element: <PrivateClientAccounts />,
                        loader: ({ params }) => {
                            rootStore.currencies.loadCurrencies();
                            rootStore.account.account.loadClientAccounts(params.userId as string);
                            return null;
                        },
                    },
                    {
                        path: PageEnum.PrivateClientComments,
                        element: <PrivateClientComments />,
                        loader: ({ params }) => {
                            rootStore.privateClient.client.loadPrivateClientComments(params.userId as string);
                            return null;
                        },
                    },
                    {
                        path: PageEnum.PrivateClientPricing,
                        element: <PrivateClientPricing />,
                    },
                    {
                        path: PageEnum.PrivateClientServices,
                        element: <PrivateClientServices />,
                    },
                ],
            },
            {
                path: PageEnum.PrivateClient,
                element: <PrivateClientProfilePage />,
                loader: ({ params }) => {
                    rootStore.privateClient.loadClient(params.userId as string);
                    return null;
                },
                children: [
                    {
                        path: PageEnum.PrivateClientProfilePersonal,
                        element: <PrivateClientProfilePersonal />,
                        loader: ({ params }) => {
                            rootStore.privateClient.client.loadPrivateClientProfile(params.userId as string);
                            return null;
                        },
                    },
                    {
                        path: PageEnum.PrivateClientProfileAddress,
                        element: <PrivateClientProfileAddress />,
                        loader: ({ params }) => {
                            rootStore.privateClient.client.loadPrivateClientAddress(params.userId as string);
                            return null;
                        },
                    },
                    {
                        path: PageEnum.PrivateClientProfileServices,
                        element: <PrivateClientProfileServices />,
                        loader: ({ params }) => {
                            rootStore.privateClient.client.loadPrivateClientService(params.userId as string);
                            return null;
                        },
                    },
                    {
                        path: PageEnum.PrivateClientProfileDocuments,
                        element: <PrivateClientProfileDocuments />,
                        loader: ({ params }) => {
                            rootStore.privateClient.client.loadPrivateClientDocuments(params.userId as string);
                            return null;
                        },
                    },
                    {
                        path: PageEnum.PrivateClientProfileDeclarations,
                        element: <PrivateClientProfileDeclarations />,
                        loader: ({ params }) => {
                            rootStore.privateClient.client.loadPrivateClientDeclaration(params.userId as string);
                            return null;
                        },
                    },
                    {
                        path: PageEnum.PrivateClientProfileAcceptAgreement,
                        element: <PrivateClientProfileAcceptAgreement />,
                    },
                ],
            },
            {
                path: PageEnum.LegalClient,
                element: <LegalClientPage />,
                loader: ({ params }) => {
                    rootStore.company.company.loadCompany(params.companyId as string);
                    return null;
                },
                children: [
                    {
                        path: PageEnum.LegalClientSummary,
                        element: <LegalClientSummary />,
                        loader: ({ params }) => {
                            rootStore.company.company.loadCompanyService(params.companyId as string);
                            return null;
                        },
                    },
                    {
                        path: PageEnum.LegalClientDocuments,
                        element: <LegalClientDocuments />,
                        loader: ({ params }) => {
                            rootStore.company.company.loadDocuments(params.companyId as string);
                            rootStore.company.company.loadPersons(params.companyId as string);
                            return null;
                        },
                    },
                    {
                        path: PageEnum.LegalClientArchivedDocuments,
                        element: <LegalClientDocuments />,
                        loader: ({ params }) => {
                            rootStore.company.company.loadDocuments(params.companyId as string, [
                                UserDocumentStatus.Deleted,
                            ]);
                            return null;
                        },
                    },
                    {
                        path: PageEnum.LegalClientAccounts,
                        element: <LegalClientAccounts />,
                        loader: ({ params }) => {
                            rootStore.currencies.loadCurrencies();
                            rootStore.company.company.loadPersons(params.companyId as string);
                            rootStore.company.company.loadCompanyAddress(params.companyId as string);
                            rootStore.account.account.loadClientAccounts(params.companyId as string);
                            return null;
                        },
                    },
                    {
                        path: PageEnum.LegalClientPricing,
                        element: <LegalClientPricing />,
                        loader: ({ params }) => {
                            rootStore.currencies.loadCurrencies();
                            rootStore.company.company.loadPersons(params.companyId as string);
                            rootStore.company.company.loadCompanyAddress(params.companyId as string);
                            rootStore.account.account.loadClientAccounts(params.companyId as string);
                            return null;
                        },
                    },
                    {
                        path: PageEnum.LegalClientServices,
                        element: <LegalClientServices />,
                    },
                    {
                        path: PageEnum.LegalClientAdministration,
                        element: <LegalClientAdministration />,
                        loader: ({ params }) => {
                            rootStore.account.account.loadClientAccounts(params.companyId as string);
                            return null;
                        },
                    },
                ],
            },
            {
                path: PageEnum.LegalClient,
                element: <LegalClientProfilePage />,
                loader: ({ params }) => {
                    rootStore.company.company.loadCompany(params.companyId as string);
                    return null;
                },
                children: [
                    {
                        path: PageEnum.LegalClientProfileInformation,
                        element: <LegalClientProfileInformation />,
                    },
                    {
                        path: PageEnum.LegalClientProfileAddress,
                        element: <LegalClientProfileAddressPage />,
                        loader: ({ params }) => {
                            rootStore.company.company.loadCompanyAddress(params.companyId as string);
                            return null;
                        },
                    },
                    {
                        path: PageEnum.LegalClientProfilePersons,
                        element: <RegistrationLegalPersonsPage />,
                        loader: ({ params }) => {
                            rootStore.company.company.loadPersons(params.companyId as string);
                            return null;
                        },
                    },
                    {
                        path: PageEnum.LegalClientProfileShareholders,
                        element: <LegalClientProfileShareholdersPage />,
                        loader: ({ params }) => {
                            rootStore.company.company.loadPersons(params.companyId as string);
                            return null;
                        },
                    },
                    {
                        path: PageEnum.LegalClientProfileDocuments,
                        element: <LegalClientProfileDocumentsPage />,
                        loader: ({ params }) => {
                            rootStore.company.company.loadDocuments(params.companyId as string);
                            rootStore.company.company.loadPersons(params.companyId as string);
                            return null;
                        },
                    },
                    {
                        path: PageEnum.LegalClientProfileServices,
                        element: <LegalClientProfileService />,
                        loader: ({ params }) => {
                            rootStore.company.company.loadCompanyService(params.companyId as string);
                            rootStore.currencies.loadCurrencies();
                            return null;
                        },
                    },
                ],
            },
            {
                path: PageEnum.Payment,
                element: <PaymentPage />,
                loader: ({ params }) => {
                    rootStore.payment.loadPayment(params.paymentId as string, params.clientId as string);
                    return null;
                },
                children: [
                    {
                        path: PageEnum.PaymentEdit,
                        element: <PaymentEdit />,
                        loader: ({ params }) => {
                            rootStore.account.account.loadClientAccounts(params.clientId as string);
                            rootStore.currencies.loadCurrencies();
                            return null;
                        },
                    },
                    {
                        path: PageEnum.PaymentSummary,
                        element: <PaymentSummary />,
                        loader: ({ params }) => {
                            rootStore.account.account.loadClientAccounts(params.clientId as string);
                            return null;
                        },
                    },
                    {
                        path: PageEnum.PaymentDocuments,
                        element: <PaymentDocuments />,
                    },
                ],
            },
        ],
    },
    {
        path: '/',
        element: <LoginPage />,
    },
    {
        path: PageEnum.Login,
        element: <LoginPage />,
    },
]);
