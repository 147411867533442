import styled from 'styled-components';

import { getAnimationStyle } from '../../styles/animation';
import { DarkGrey, Mystic, White } from '../../styles/colors';
import { Flex } from '../../styles/flex';
import Close from './assets/close.svg?react';

export const CloseIcon = styled(Close)`
    fill: ${DarkGrey};
    transform: rotate(0deg);
    ${getAnimationStyle(['transform'])}
`;

export const CloseWrapper = styled.div`
    width: 48px;
    height: 48px;
    background-color: ${White};
    border: 1px solid ${Mystic};
    border-radius: 50%;
    ${Flex.HorizontalCenter.VerticalCenter.Style}

    :hover {
        ${CloseIcon} {
            transform: rotate(-180deg);
        }
    }
`;
