import { TemplateNotificationFormEnum } from '@shared-form/template-notification-form/template-notification-form.enum';
import { AdminCreateNotificationMutationVariables, AdminCreateTemplateMutationVariables } from '@shared-graphql';

export const initialValues: AdminCreateNotificationMutationVariables | AdminCreateTemplateMutationVariables = {
    [TemplateNotificationFormEnum.Template]: '',
    [TemplateNotificationFormEnum.Subject]: '',
    [TemplateNotificationFormEnum.ClientIds]: [],
    [TemplateNotificationFormEnum.UserStatus]: null,
    [TemplateNotificationFormEnum.UserType]: null,
};
