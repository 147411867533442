import { ReactNode } from 'react';

import { ActionsCell } from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications-table-row/template-or-notification-row/cells/actions-cell/actions-cell';
import { ClientTypeCell } from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications-table-row/template-or-notification-row/cells/client-type-cell';
import { DateCell } from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications-table-row/template-or-notification-row/cells/date-cell';
import { IDCell } from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications-table-row/template-or-notification-row/cells/id-cell/id-cell';
import { RecipientsCell } from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications-table-row/template-or-notification-row/cells/recipients-cell/recipients-cell';
import { StatusCell } from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications-table-row/template-or-notification-row/cells/status-cell';
import { SubjectCell } from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications-table-row/template-or-notification-row/cells/subject-cell';

export const templateOrNotificationRowOptions: Array<ReactNode | any> = [
    IDCell,
    RecipientsCell,
    SubjectCell,
    DateCell,
    ClientTypeCell,
    StatusCell,
    ActionsCell,
];
