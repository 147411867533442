import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Neutral50 } from '@shared-style/colors';

export const NotificationInstancePageContent = styled.div`
    margin-top: 24px;
    padding: 36px 24px 90px;
    overflow-y: scroll;
    height: 100%;
    background-color: ${Neutral50};
`;
export const NotificationInstancePageDescription = styled.p`
    ${Typography.Paragraph18.Medium.Neutral900.Style};
    margin-bottom: 32px;
`;
