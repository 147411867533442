import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Concrete } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';

export const ViewNotificationContent = styled.div`
    ${Flex.Column.Style};
    gap: 32px;
`;

export const ViewNotificationInfoBlock = styled.div`
    ${Flex.Column.Style};
    gap: 10px;
`;

export const ViewNotificationInfoTitle = styled.h3`
    ${Typography.Paragraph12.SemiBold.Neutral500.Style};
`;

export const ViewNotificationInfoContent = styled.div`
    ${Typography.Paragraph14.SemiBold.Neutral700.Style};
    max-width: 585px;
    ${Flex.Wrap.Style};
    gap: 12px;
`;

export const ViewNotificationInfoContentBadge = styled.div`
    border-radius: 8px;
    background: ${Concrete};
    padding: 8px 12px;
    ${Flex.VerticalCenter.HorizontalCenter.Style};
    ${Typography.Paragraph14.Normal.DimGray.Style};
    line-height: normal;
`;
export const ViewNotificationButtonsWrapper = styled.div`
    ${Flex.VerticalCenter.Style};
    gap: 16px;
    button {
        width: 134px;
    }
`;
