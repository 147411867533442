import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';

import { CompanyActivatedStatus } from '@shared-graphql';
import { useIfxCallbackUrl } from '@shared-hook/ifx-callback-url/ifx-callback-url.hook';
import { ClickEventType } from '@shared-type/click-event.type';
import { isString, isTrue } from '@shared-util/is-data';

import { ClientSummaryEditPhoneForm } from '@page/common/client-summary/client-summary-edit-phone-form/client-summary-edit-phone-form';
import { ApplicationAnnualTurnover } from '@page/common/client-summary/private-client-summary-application-annual-turnover/private-client-summary-application-annual-turnover';
import { CountryLabel } from '@page/common/client-summary/private-client-summary-country-label/private-client-summary-country-label';
import { GridRow } from '@page/common/client-summary/private-client-summary-row/private-client-summary-row';
import {
    SummaryGroup,
    SummaryGroupTitle,
} from '@page/common/client-summary/private-client-summary-row/private-client-summary-row.styles';
import {
    BlockFooter,
    BlockWrapper,
    GridColumn,
} from '@page/private-client/private-client-summary/private-client-summary.styles';
import { useCompanyActionsSelector } from '@selector/company/use-company.actions-selector';

import { SummaryStatusButtons } from './legal-client-summary-status-buttons/legal-client-summary-status-buttons';
import { useLegalClientSummary } from './legal-client-summary.hook';

export const LegalClientSummary = observer(() => {
    const [
        {
            id,
            email,
            elcoinId,
            phone,
            companyName,
            status,
            clientStatus,
            verificationRejectionTitle,
            verificationRejectionDate,
            country,
            moneyTurnover,
            moneyTurnoverCurrency,
            isApplicationProfileCompleted,
            numberOfCustomers,
            numberOfPersons,
            isAccountInAnotherBank,
            isSubmitFinancialTaxReport,
            preferredAccountCurrency,
            spendPlan,
            industryDescription,
            isCompanyAddressSame,
            legalAddress,
            postalAddress,
            biggestClient,
            companySite,
            registrationDate,
            termsAccepted,
            userId,
        },
        handleAction,
    ] = useLegalClientSummary();
    const { setCompanyOwnerPhone } = useCompanyActionsSelector();
    const { callbackURL, getIbanqCallbackUrl } = useIfxCallbackUrl({ clientId: id });

    const shouldRenderVerificationRejectionDate = isString(verificationRejectionTitle);

    return (
        <BlockWrapper>
            <SummaryGroup>
                <SummaryGroupTitle>Company Data</SummaryGroupTitle>
                <GridColumn>
                    <GridRow title="ID" data={id} />
                    <GridRow title="Company name" data={companyName} />
                    <GridRow title="Code" data={elcoinId} />
                    <GridRow title="Status" data={status} />
                    {shouldRenderVerificationRejectionDate && (
                        <GridRow title={verificationRejectionTitle} data={verificationRejectionDate} />
                    )}
                    <GridRow title="Registration country" data={<CountryLabel countryCode={country} />} />
                    <GridRow
                        title="Company registration date"
                        data={registrationDate != null ? format(registrationDate, 'dd MMMM yyyy') : 'not defined'}
                    />
                    <GridRow title="Company industries" data={industryDescription} />
                    {companySite != null ? (
                        <GridRow
                            title="Company Site"
                            data={
                                <a href={companySite} target="_blank" rel="noreferrer">
                                    {companySite}
                                </a>
                            }
                        />
                    ) : null}
                    <GridRow title="Is Terms accepted" data={isTrue(termsAccepted) ? 'Yes' : 'No'} />
                </GridColumn>
            </SummaryGroup>
            <SummaryGroup>
                <SummaryGroupTitle>Owner</SummaryGroupTitle>
                <GridColumn>
                    <GridRow title="Email" data={email} />
                    <GridRow
                        title="Phone"
                        data={
                            <ClientSummaryEditPhoneForm
                                phone={phone}
                                userId={userId}
                                updateUserPhone={setCompanyOwnerPhone}
                            />
                        }
                    />
                    <GridRow title="Client status" data={clientStatus} />
                </GridColumn>
            </SummaryGroup>
            <SummaryGroup>
                <SummaryGroupTitle>Company service</SummaryGroupTitle>
                <GridColumn>
                    <GridRow
                        title="Application annual turnover"
                        data={
                            <ApplicationAnnualTurnover
                                moneyTurnover={Number(moneyTurnover)}
                                currency={moneyTurnoverCurrency}
                            />
                        }
                    />
                    <GridRow title="Number of customers" data={numberOfCustomers} />
                    <GridRow title="Number of persons" data={numberOfPersons} />
                    <GridRow title="Have account in another bank" data={isAccountInAnotherBank} />
                    <GridRow title="Do you submit financial tax report" data={isSubmitFinancialTaxReport} />
                    <GridRow title="Preferred account currencies" data={preferredAccountCurrency} />
                    <GridRow
                        title="Spend plan in SENDS"
                        data={
                            <ApplicationAnnualTurnover
                                moneyTurnover={Number(spendPlan)}
                                currency={moneyTurnoverCurrency}
                            />
                        }
                    />
                </GridColumn>
            </SummaryGroup>
            <SummaryGroup>
                <SummaryGroupTitle>
                    {isCompanyAddressSame ? 'Legal and Postal address' : 'Legal Address'}
                </SummaryGroupTitle>
                <GridColumn>
                    <GridRow title="Country" data={<CountryLabel countryCode={legalAddress.country} />} />
                    <GridRow title="City" data={legalAddress.city} />
                    <GridRow title="Street" data={legalAddress.street} />
                    <GridRow title="Building" data={legalAddress.building} />
                    <GridRow title="Zip" data={legalAddress.zip} />
                </GridColumn>
            </SummaryGroup>
            {!isCompanyAddressSame && (
                <SummaryGroup>
                    <SummaryGroupTitle>Postal Address</SummaryGroupTitle>
                    <GridColumn>
                        <GridRow title="Country" data={<CountryLabel countryCode={postalAddress.country} />} />
                        <GridRow title="City" data={postalAddress.city} />
                        <GridRow title="Street" data={postalAddress.street} />
                        <GridRow title="Building" data={postalAddress.building} />
                        <GridRow title="Zip" data={postalAddress.zip} />
                    </GridColumn>
                </SummaryGroup>
            )}
            {biggestClient != null && (
                <SummaryGroup>
                    <SummaryGroupTitle>Biggest Client</SummaryGroupTitle>
                    <GridColumn>
                        <GridRow title="Client ID" data={biggestClient.id ?? ''} />
                        <GridRow title="Name" data={biggestClient.name ?? ''} />
                        <GridRow
                            title="Website"
                            data={
                                biggestClient.website != null ? (
                                    <a href={biggestClient.website} target="_blank" rel="noreferrer">
                                        {biggestClient.website}
                                    </a>
                                ) : (
                                    '-'
                                )
                            }
                        />
                        <GridRow title="Country" data={<CountryLabel countryCode={biggestClient.country} />} />
                        <GridRow title="Subject Of Business" data={biggestClient.subjectOfBusiness ?? ''} />
                    </GridColumn>
                </SummaryGroup>
            )}
            {isTrue(callbackURL) && (
                <SummaryGroup>
                    <GridColumn>
                        <GridRow title="IFX Callback URL" data={callbackURL} isCopyEnabled />
                    </GridColumn>
                </SummaryGroup>
            )}
            <BlockFooter>
                <SummaryStatusButtons
                    handleAction={handleAction}
                    status={status as CompanyActivatedStatus}
                    isApplicationProfileCompleted={isApplicationProfileCompleted}
                    getIbanqCallbackUrl={getIbanqCallbackUrl as ClickEventType}
                />
            </BlockFooter>
        </BlockWrapper>
    );
});
