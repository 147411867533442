import styled from 'styled-components';

import Info from '@shared-asset/info.svg?react';
import { ButtonBorderedStyle } from '@shared-atom/button/button.style';
import { PrimeBlue } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';

export const ActionsWrapper = styled.div`
    ${Flex.JustifyLeft.Style}
    padding: 0;
    align-self: flex-start;
    ${ButtonBorderedStyle} {
        width: 56px;
        padding: 0 8px;
    }
`;

export const InfoIcon = styled(Info)`
    width: 20px;
    height: 20px;
    fill: ${PrimeBlue};
`;

export const IconWrapper = styled.div`
    border: 1px solid ${PrimeBlue};
    border-radius: 8px;
    width: 44px;
    height: 40px;

    ${Flex.HorizontalCenter.VerticalCenter.Style}
`;
