import { useField } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import { ButtonTransparent } from '@shared-atom/button/button-transparent';
import { ButtonTypeEnum } from '@shared-atom/button/button-type.enum';
import { CustomInput } from '@shared-component/input/custom-input';
import { InputProps } from '@shared-component/input/input';
import { InputTypeEnum } from '@shared-component/input/input-type.enum';
import { usePrevious } from '@shared-hook/utils/use-previous';
import { LangKeysEnum } from '@shared-store/store-localization.interface';
import { OnEventType } from '@shared-type/on-event.type';
import { isExist } from '@shared-util/is-data';
import { noop } from '@shared-util/noop';

import { CustomMagnifierIcon, CustomSearchWrapper } from './input-search.styles';

export interface InputSearchProps extends Omit<InputProps, 'type' | 'name' | 'title'> {
    name?: string;
    placeholder?: string | LangKeysEnum;
    onChange?: OnEventType<string>;
    title?: string;
    isSpacesDisabled?: boolean;
}

export const CustomInputSearch = observer(
    ({
        title = '',
        name = 'search',
        onChange = noop,
        placeholder = 'search',
        isSpacesDisabled = false,
    }: InputSearchProps) => {
        const [{ value }] = useField(name);

        const previousValue = usePrevious(value);

        const isUpdatedValue = isExist(previousValue) && previousValue !== value;

        useEffect(() => void (isUpdatedValue && onChange(value)), [value]);

        return (
            <CustomInput
                type={InputTypeEnum.Text}
                title={title}
                name={name}
                placeholder={placeholder}
                isSpacesDisabled={isSpacesDisabled}
            >
                <ButtonTransparent type={ButtonTypeEnum.Submit}>
                    <CustomSearchWrapper>
                        <CustomMagnifierIcon />
                    </CustomSearchWrapper>
                </ButtonTransparent>
            </CustomInput>
        );
    }
);
