import { ReactElement } from 'react';

import { Translate } from '@shared-atom/translate/translate';
import { AccountAdministration } from '@shared-graphql';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { OnEventType } from '@shared-type/on-event.type';
import { isString } from '@shared-util/is-data';

import { CommissionProfileInfoCurrency } from '@page/commission-profile/commission-profile-info/commission-profile-info-currency/commission-profile-info-currency';
import { CommissionListAdministrationProps } from '@page/commission-profile/commission-profile-info/commission-profile-info.options';
import {
    CommissionItem,
    CommissionTitle,
    Description,
    DescriptionInfo,
    List,
} from '@page/commission-profile/commission-profile-info/commission-profile-info.styles';

interface CommissionProfileInfoAdministrationListProps {
    list: CommissionListAdministrationProps[];
    accountAdministration: Omit<AccountAdministration, 'balanceFee' | 'userType'>;
    children?: OnEventType<any, ReactElement>;
}

export const CommissionProfileInfoAdministrationList = ({
    list,
    accountAdministration,
    children,
}: CommissionProfileInfoAdministrationListProps) => {
    const hasChild = children !== undefined;

    return (
        <List>
            {list
                .filter((item: any) => item.id !== 'userType')
                .map(item => {
                    const shouldRenderAmount = accountAdministration[item.id] === 0;
                    const shouldRenderDescription = isString(item.info);

                    return (
                        <CommissionItem key={item.title}>
                            <CommissionTitle hasCommission>
                                <Translate langKey={item.title} />
                            </CommissionTitle>
                            <Description isHalf={hasChild}>
                                <>
                                    {shouldRenderAmount && (
                                        <Translate langKey={LocalizationEnum.CommissionProfileAdministrationFree} />
                                    )}
                                    {!shouldRenderAmount && (
                                        <CommissionProfileInfoCurrency
                                            currency={accountAdministration[item.id] as number}
                                        />
                                    )}
                                    {shouldRenderDescription && (
                                        <DescriptionInfo>
                                            <Translate langKey={item.info ?? ''} />
                                        </DescriptionInfo>
                                    )}
                                </>
                            </Description>
                            {children !== undefined && <Description isHalf={hasChild}>{children(item)}</Description>}
                        </CommissionItem>
                    );
                })}
        </List>
    );
};
