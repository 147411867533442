import { useStore } from '@store/store-context';

export const useCompanyActionsSelector = () => {
    const {
        loadCompany,
        loadCompanyAddress,
        loadPersons,
        loadCompanyService,
        updateCompanyStatus,
        setCompanyOwnerPhone,
    } = useStore().company.company;

    return {
        loadCompanyAddress,
        loadCompany,
        loadPersons,
        loadCompanyService,
        updateCompanyStatus,
        setCompanyOwnerPhone,
    };
};
