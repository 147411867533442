import { $enum } from 'ts-enum-util';

import { TemplateUserStatus, TemplateUserType } from '@shared-graphql';

export const userTypeValues = $enum(TemplateUserType).map(value => ({
    value,
    label: value,
}));

export const userStatusValues = $enum(TemplateUserStatus).map(value => ({
    value,
    label: value,
}));
