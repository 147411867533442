import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Flex } from '@shared-style/flex';

export const EmptyTableComponentWrapper = styled.div`
    ${Flex.Column.VerticalCenter.HorizontalCenter.Style};
    gap: 29px;
`;

export const EmptyTableComponentInfoText = styled.div`
    ${Typography.Paragraph16.Medium.Neutral700.Center.Style};
    white-space: pre-line;
`;
