import { useLazyQuery, useMutation } from '@apollo/client';
import { GraphQLError } from 'graphql/index';
import { useCallback, useContext, useEffect } from 'react';

import { InformationModalContext } from '@shared-component/information-modal/information-modal.context';
import { InformationModalEnum } from '@shared-component/information-modal/information-modal.enum';
import { ModelContext } from '@shared-component/modal/modal-context';
import { ModalEnum } from '@shared-component/modal/modal.enum';
import {
    AdminDeleteNotification,
    AdminDeleteNotificationMutation,
    AdminGetNotification,
    AdminGetNotificationQuery,
    AdminSendNotification,
    AdminSendNotificationMutation,
    DefaultAnswer,
} from '@shared-graphql';
import { useCurrentNavigation, useRouterNavigate } from '@shared-hook/navigation/use-current-navigation.hook';
import { getGraphQLErrorMessages } from '@shared-util/get-graphql-error-code';
import { isExist, isTrue } from '@shared-util/is-data';

import { PageEnum } from '@enum/page.enum';
import { EmailsAndNotificationsTableFilterFormEnum } from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications-table-filter/emails-and-notifications-table-filter-form/emails-and-notifications-table-filter-form.enum';
import { NotificationInstanceTypeEnum } from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications.enum';

export const useCreateNotificationPage = () => {
    const { params } = useCurrentNavigation();
    const { onOpen: onInformationModalOpen } = useContext(InformationModalContext);
    const { onOpen: onAdminModalOpen } = useContext(ModelContext);

    const navigate = useRouterNavigate();

    const [
        adminGetNotification,
        { data: adminGetNotificationData, error: adminGetNotificationError, loading: isAdminGetNotificationLoading },
    ] = useLazyQuery<AdminGetNotificationQuery>(AdminGetNotification);

    const [
        adminDeleteNotification,
        {
            data: adminDeleteNotificationData,
            error: adminDeleteNotificationError,
            loading: isAdminDeleteNotificationLoading,
        },
    ] = useMutation<AdminDeleteNotificationMutation>(AdminDeleteNotification);

    const [
        adminSendNotification,
        { data: adminSendNotificationData, error: adminSendNotificationError, loading: isAdminSendNotificationLoading },
    ] = useMutation<AdminSendNotificationMutation>(AdminSendNotification);

    useEffect(() => {
        adminGetNotification({ variables: { notificationId: params.id } });
    }, [params.id]);

    useEffect(() => {
        if (isExist((adminDeleteNotificationData?.adminDeleteNotification as DefaultAnswer)?.status)) {
            if (isTrue((adminDeleteNotificationData?.adminDeleteNotification as DefaultAnswer)?.status)) {
                setTimeout(
                    () =>
                        onInformationModalOpen(InformationModalEnum.SuccessInformationModal, {
                            text: 'Your notification has been successfully deleted. Thank you for using our service!',
                        }),
                    500
                );
                navigate(PageEnum.EmailsAndNotifications, {
                    ...params,
                    [EmailsAndNotificationsTableFilterFormEnum.NotificationInstanceType]:
                        NotificationInstanceTypeEnum.Notifications,
                });
            } else {
                onInformationModalOpen(InformationModalEnum.FailureInformationModal, {
                    text: 'An error occurred while deleting your notification. Please check the details and try again.',
                });
            }
        }
    }, [adminDeleteNotificationData]);

    useEffect(() => {
        if (isExist((adminSendNotificationData?.adminSendNotification as DefaultAnswer)?.status)) {
            if (isTrue((adminSendNotificationData?.adminSendNotification as DefaultAnswer)?.status)) {
                setTimeout(
                    () =>
                        onInformationModalOpen(InformationModalEnum.SuccessInformationModal, {
                            text: 'Your notification has been successfully sent. Thank you for using our service!',
                        }),
                    500
                );
                navigate(PageEnum.EmailsAndNotifications, {
                    ...params,
                    [EmailsAndNotificationsTableFilterFormEnum.NotificationInstanceType]:
                        NotificationInstanceTypeEnum.Notifications,
                });
            } else {
                onInformationModalOpen(InformationModalEnum.FailureInformationModal, {
                    text: 'An error occurred while sending your notification. Please check the details and try again.',
                });
            }
        }
    }, [adminSendNotificationData]);

    useEffect(() => {
        if (isExist(adminGetNotificationError)) {
            onInformationModalOpen(InformationModalEnum.FailureInformationModal, {
                text:
                    getGraphQLErrorMessages(adminGetNotificationError?.graphQLErrors as GraphQLError[])[0] ??
                    adminGetNotificationError?.message ??
                    'An error occurred',
            });
        }
    }, [adminGetNotificationError]);

    useEffect(() => {
        if (isExist(adminDeleteNotificationError)) {
            onInformationModalOpen(InformationModalEnum.FailureInformationModal, {
                text:
                    getGraphQLErrorMessages(adminDeleteNotificationError?.graphQLErrors as GraphQLError[])[0] ??
                    adminDeleteNotificationError?.message ??
                    'An error occurred',
            });
        }
    }, [adminDeleteNotificationError]);

    useEffect(() => {
        if (isExist(adminSendNotificationError)) {
            onInformationModalOpen(InformationModalEnum.FailureInformationModal, {
                text:
                    getGraphQLErrorMessages(adminSendNotificationError?.graphQLErrors as GraphQLError[])[0] ??
                    adminSendNotificationError?.message ??
                    'An error occurred',
            });
        }
    }, [adminSendNotificationError]);

    const onSendNotification = useCallback(() => {
        onAdminModalOpen(ModalEnum.ConfirmationModal, {
            componentProps: {
                confirmBtnText: 'Send',
                confirmText: 'Are you sure you want to send this notification to users?',
                onConfirmClick: () => adminSendNotification({ variables: { notificationId: params.id } }),
            },
        });
    }, []);

    const onDeleteNotification = useCallback(() => {
        onAdminModalOpen(ModalEnum.ConfirmationModal, {
            componentProps: {
                confirmBtnText: 'Delete',
                confirmText: 'Are you sure you want to delete this notification to users?',
                onConfirmClick: () => adminDeleteNotification({ variables: { notificationId: params.id } }),
            },
        });
    }, []);

    return {
        notification: adminGetNotificationData?.adminGetNotification,
        isAdminGetNotificationLoading,
        onSendNotification,
        onDeleteNotification,
        isLoading: isAdminDeleteNotificationLoading || isAdminSendNotificationLoading,
    };
};
