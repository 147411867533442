import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { getAnimationStyle } from '@shared-style/animation';
import { Neutral200, Neutral50, Neutral700 } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';
import { IsActiveType } from '@shared-type/common.type';

export const SendFlowSelectorContainer = styled.div`
    ${Flex.Row.Style};
    padding: 2px;
    border: 1px solid ${Neutral200};
    border-radius: 10px;
    width: fit-content;
`;

export const SendFlowSelectorButton = styled.div<IsActiveType>`
    ${Flex.Row.HorizontalCenter.VerticalCenter.Style};
    border-radius: 8px;
    padding: 10px 16px;
    ${Typography.Paragraph14.SemiBold.Style};
    ${getAnimationStyle(['background-color'])}
    ${({ isActive = false }) =>
        isActive
            ? `background-color: #2D465D; color: ${Neutral50}; cursor: default; pointer-events: none`
            : `background-color: transparent; color: ${Neutral700}; cursor: pointer`}
`;
