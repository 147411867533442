import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Neutral200 } from '@shared-style/colors';

export const RecipientsCellTooltipItem = styled.div`
    padding: 9px 0;
    border-bottom: 1px solid ${Neutral200};
    &:last-child {
        border-bottom: none;
    }
    ${Typography.Paragraph12.SemiBold.Left.HiddenEllipsis.Neutral700.Style};
    font-size: 9px;
    line-height: 16px;
    width: 143px;
`;
