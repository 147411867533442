import { useLazyQuery } from '@apollo/client';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import { useFiltersCompose } from '@shared-component/filters/filters.hook';
import { useNotificationInstanceTypeFilter } from '@shared-component/filters/notification-type-filter/getting-type.hook';
import { usePaginationFilter } from '@shared-component/filters/pagination-filter/pagination-filter.hook';
import {
    AdminGetNotificationList,
    AdminGetNotificationListQuery,
    AdminGetTemplateList,
    AdminGetTemplateListQuery,
    Notification,
    Template,
} from '@shared-graphql';
import { useCurrentNavigation, useRouterNavigate } from '@shared-hook/navigation/use-current-navigation.hook';
import { usePrevious } from '@shared-hook/utils/use-previous';
import { isTrue } from '@shared-util/is-data';
import { isDeepEqualObject } from '@shared-util/is-object';

import { PageEnum } from '@enum/page.enum';
import { EmailsAndNotificationsTableFilterFormEnum } from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications-table-filter/emails-and-notifications-table-filter-form/emails-and-notifications-table-filter-form.enum';
import { NotificationInstanceTypeEnum } from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications.enum';
import { getFilters } from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications.utils';

const FIRST_INDEX = 0;
const LIMIT_ROWS_PER_PAGE = 20;

export const useEmailsAndNotifications = () => {
    const navigate = useRouterNavigate();
    const { searchParams: params } = useCurrentNavigation();
    const { notificationInstanceType } = params;

    const [
        adminGetNotificationList,
        { data: adminGetNotificationListData, loading: isAdminGetNotificationListLoading },
    ] = useLazyQuery<AdminGetNotificationListQuery>(AdminGetNotificationList);
    const [adminGetTemplateList, { data: adminGetTemplateListData, loading: isAdminGetTemplateListLoading }] =
        useLazyQuery<AdminGetTemplateListQuery>(AdminGetTemplateList);

    const isLoading = useMemo(
        () => isAdminGetNotificationListLoading || isAdminGetTemplateListLoading,
        [isAdminGetNotificationListLoading, isAdminGetTemplateListLoading]
    );

    const instanceList: Template[] | Notification[] = useMemo(() => {
        if (notificationInstanceType === NotificationInstanceTypeEnum.Notifications) {
            return (
                (adminGetNotificationListData?.adminGetNotificationList?.notifications as unknown as Notification[]) ??
                ([] as Notification[])
            );
        }
        return (adminGetTemplateListData?.adminGetTemplateList.templates as Template[]) ?? ([] as Template[]);
    }, [adminGetNotificationListData, adminGetTemplateListData, notificationInstanceType]);

    const totalInstances = useMemo(() => {
        if (notificationInstanceType === NotificationInstanceTypeEnum.Notifications) {
            return adminGetNotificationListData?.adminGetNotificationList?.total ?? 0;
        }
        if (notificationInstanceType === NotificationInstanceTypeEnum.Templates) {
            return adminGetTemplateListData?.adminGetTemplateList.total ?? 0;
        }
        return 0;
    }, [adminGetNotificationListData, adminGetTemplateListData, notificationInstanceType]);

    const [
        filters,
        { onPageChange, onSearch, onNotificationInstanceTypeChange },
        { pageSize, currentPage, isDebounce },
    ] = useFiltersCompose(
        usePaginationFilter({ total: totalInstances, limit: LIMIT_ROWS_PER_PAGE }),
        // useSearchFilter(),
        useNotificationInstanceTypeFilter(
            isTrue(notificationInstanceType) ? notificationInstanceType : NotificationInstanceTypeEnum.Notifications
        )
    );

    const tableRef = useRef();

    const previousFilters = usePrevious(filters);

    useEffect(() => {
        if (!isDeepEqualObject(previousFilters, filters)) {
            if (
                filters[EmailsAndNotificationsTableFilterFormEnum.NotificationInstanceType] ===
                NotificationInstanceTypeEnum.Notifications
            ) {
                adminGetNotificationList({
                    variables: {
                        // filters: {
                        ...getFilters(filters),
                        // },
                    },
                });
            } else {
                adminGetTemplateList({
                    variables: {
                        // filters: {
                        ...getFilters(filters),
                        // },
                    },
                });
            }
        }
    }, [isLoading, filters]);

    useEffect(
        () => void onPageChange(FIRST_INDEX),
        [
            // filters.search,
            filters.notificationInstanceType,
        ]
    );

    const onResetFilters = useCallback(() => {
        if (isTrue(filters[EmailsAndNotificationsTableFilterFormEnum.NotificationInstanceType])) {
            navigate(PageEnum.EmailsAndNotifications, {
                [EmailsAndNotificationsTableFilterFormEnum.NotificationInstanceType]:
                    NotificationInstanceTypeEnum.Templates,
            });
        }
        // if (isTrue(filters.search)) {
        //     onSearch('');
        // }
    }, [
        // filters.search,
        filters.notificationInstanceType,
    ]);

    return {
        filters,
        instanceList,
        isLoading: isLoading || (pageSize === 1 && totalInstances > LIMIT_ROWS_PER_PAGE) || isDebounce,
        pageSize,
        currentPage,
        onPageChange,
        onSearch,
        tableRef,
        onResetFilters,
        totalInstancesCount: totalInstances,
        limitRowsPerPage: LIMIT_ROWS_PER_PAGE,
        onNotificationInstanceTypeChange,
    };
};
