import { parse } from 'date-fns';
import { Formik } from 'formik';
import { useMemo } from 'react';

import { StateControlButtons } from '@shared-component/table/state-control-buttons/state-control-buttons';
import { TableFiltersWrapper } from '@shared-component/table/table.styles';
import { OnEventType } from '@shared-type/on-event.type';
import { isTrue } from '@shared-util/is-data';
import { noop } from '@shared-util/noop';

import { EmailsAndNotificationsTableFilterForm } from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications-table-filter/emails-and-notifications-table-filter-form/emails-and-notifications-table-filter-form';
import { FilterFormInterface } from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications-table-filter/emails-and-notifications-table-filter-form/emails-and-notifications-table-filter-form.interface';
import { TopLevelOfFilters } from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications-table-filter/emails-and-notifications-table-filter.styles';
import { FilterByNotificationInstanceType } from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications-table-filter/filter-by-notification-instance-type/filter-by-notification-instance-type';
import { NotificationInstanceTypeEnum } from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications.enum';

interface EmailsAndNotificationsTableFilterInterface extends FilterFormInterface {
    filters: Record<string, any>;
    onResetFilters: typeof noop;
    instancesCount: number;
    onNotificationInstanceTypeChange: OnEventType<NotificationInstanceTypeEnum | string>;
}

export const EmailsAndNotificationsTableFilter = ({
    filters: { notificationInstanceType, createdFrom, createdTo, clientType, clientStatus },
    onNotificationInstanceTypeChange,
    onResetFilters,
    instancesCount,
    onClientStatusChange,
    onClientTypeChange,
    onCreatedFromChange,
    onCreatedToChange,
}: EmailsAndNotificationsTableFilterInterface) => {
    const initialValues = useMemo(
        () => ({
            clientType,
            clientStatus,
            createdFrom: isTrue(createdFrom) ? parse(createdFrom, 'dd-MM-yyyy', new Date()) : '',
            createdTo: isTrue(createdTo) ? parse(createdTo, 'dd-MM-yyyy', new Date()) : '',
        }),
        [clientStatus, clientType, createdFrom, createdTo]
    );

    return (
        <>
            <TopLevelOfFilters>
                <FilterByNotificationInstanceType
                    notificationInstanceTypeFilter={notificationInstanceType}
                    onNotificationInstanceTypeChange={onNotificationInstanceTypeChange}
                />
                <StateControlButtons onResetFilters={onResetFilters} />
            </TopLevelOfFilters>
            <TableFiltersWrapper>
                <Formik initialValues={initialValues} onSubmit={noop} enableReinitialize>
                    <EmailsAndNotificationsTableFilterForm
                        onClientStatusChange={onClientStatusChange}
                        onClientTypeChange={onClientTypeChange}
                        onCreatedFromChange={onCreatedFromChange}
                        onCreatedToChange={onCreatedToChange}
                        instancesCount={instancesCount}
                    />
                </Formik>
            </TableFiltersWrapper>
        </>
    );
};
