import { GraphQLError } from 'graphql';

import { isExist } from '@shared-util/is-data';

export const getGraphQLErrorCode = (apolloError: GraphQLError[]) =>
    isExist(apolloError) && apolloError[0].extensions?.code;

export const getGraphQLErrorMessages = (apolloError: GraphQLError[]) =>
    isExist(apolloError)
        ? (apolloError[0].extensions?.invalidArgs as Array<Error>)?.map(({ message }) => message) ?? []
        : [];
