import styled from 'styled-components';

import { White } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';

export const BlockWrapper = styled.div`
    padding: 32px;
    border-radius: 0 0 16px 16px;
    background-color: ${White};
    ${Flex.Column.Style};
    gap: 36px;
`;

export const GridColumn = styled.div`
    display: grid;
    grid-template-columns: 1fr 3fr 56px;
    grid-gap: 0 36px;
`;

export const BlockFooter = styled.div`
    margin: 32px 0 24px;
    ${Flex.JustifyRight.Style};
    flex-wrap: wrap;
    gap: 10px;
`;
