import styled from 'styled-components';

import Edit from '@shared-asset/pencil.svg?react';
import { Typography } from '@shared-atom/typography/typography';
import { DarkGrey } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';

export const EditIcon = styled(Edit)`
    width: 16px;
    height: 16px;
    fill: ${DarkGrey};
    cursor: pointer;
`;
export const EditFormWrapper = styled.div`
    padding: 8px 0;
    gap: 4px;
    ${Flex.VerticalCenter.Style};
    ${Typography.Paragraph14.SemiBold.Neutral700.Style}
    a, span {
        ${Typography.Paragraph14.SemiBold.Neutral700.Style}
    }
`;
