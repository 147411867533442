import { Formik } from 'formik';
import { FC, useMemo } from 'react';

import { ButtonPrimaryStyle } from '@shared-atom/button/button.style';
import { CustomInputSearch } from '@shared-component/input/input-search/custom-input-search';
import { TableTitle, TableHeaderWrapper, TableSearchWrapper } from '@shared-component/table/table.styles';
import { useCurrentNavigation, useRouterNavigate } from '@shared-hook/navigation/use-current-navigation.hook';
import { OnEventType } from '@shared-type/on-event.type';
import { noop } from '@shared-util/noop';

import { PageEnum } from '@enum/page.enum';
import { NotificationInstanceTypeEnum } from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications.enum';

interface EmailsAndNotificationsTHeaderInterface {
    onSearchChange: OnEventType<string>;
    filters: Record<string, any>;
}

export const EmailsAndNotificationsTableHeader: FC<EmailsAndNotificationsTHeaderInterface> = ({
    filters: { search },
    onSearchChange,
}) => {
    const navigate = useRouterNavigate();
    const { searchParams } = useCurrentNavigation();

    const { notificationInstanceType } = searchParams;

    const buttonText = useMemo(
        () =>
            `Create ${
                notificationInstanceType === NotificationInstanceTypeEnum.Notifications ? 'notification' : 'email'
            }`,
        [notificationInstanceType]
    );

    return (
        <TableHeaderWrapper>
            <TableTitle>Emails & Notifications</TableTitle>
            <TableSearchWrapper>
                <Formik initialValues={{ search }} onSubmit={noop} enableReinitialize>
                    <CustomInputSearch placeholder="Search" onChange={onSearchChange} />
                </Formik>
            </TableSearchWrapper>
            <ButtonPrimaryStyle
                onClick={() =>
                    navigate(
                        notificationInstanceType === NotificationInstanceTypeEnum.Notifications
                            ? PageEnum.NotificationCreate
                            : PageEnum.EmailCreate
                    )
                }
            >
                {buttonText}
            </ButtonPrimaryStyle>
        </TableHeaderWrapper>
    );
};
