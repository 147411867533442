import { Form, Formik } from 'formik';

import { ButtonBordered } from '@shared-atom/button/button-bordered';
import { ButtonPrimary } from '@shared-atom/button/button-primary';
import { ButtonTypeEnum } from '@shared-atom/button/button-type.enum';
import { TemplateNotificationFormByClientIdsFields } from '@shared-form/template-notification-form/template-notification-form-by-client-ids-fields/template-notification-form-by-client-ids-fields';
import { TemplateNotificationFormByTypeAndStatusFields } from '@shared-form/template-notification-form/template-notification-form-by-type-and-status-fields/template-notification-form-by-type-and-status-fields';
import { initialValues } from '@shared-form/template-notification-form/template-notification-form.initials';
import { TemplateNotificationInterface } from '@shared-form/template-notification-form/template-notification-form.interface';
import {
    TemplateNotificationButtonsWrapper,
    TemplateNotificationWrapper,
} from '@shared-form/template-notification-form/template-notification-form.styles';
import { TemplateNotificationFormValidation } from '@shared-form/template-notification-form/template-notification-form.validation';
import { AdminCreateNotificationMutationVariables, AdminCreateTemplateMutationVariables } from '@shared-graphql';
import { useRouterNavigate } from '@shared-hook/navigation/use-current-navigation.hook';

import { PageEnum } from '@enum/page.enum';
import { SendFlowEnum } from '@page/emails-and-notifications/components/send-flow-selector/send-flow-selector.enum';
import { EmailsAndNotificationsTableFilterFormEnum } from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications-table-filter/emails-and-notifications-table-filter-form/emails-and-notifications-table-filter-form.enum';
import { NotificationInstanceTypeEnum } from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications.enum';

export const TemplateNotificationForm = ({ onCreate, isLoading, currentSendFlow }: TemplateNotificationInterface) => {
    const navigate = useRouterNavigate();

    const onSubmit = (values: AdminCreateNotificationMutationVariables | AdminCreateTemplateMutationVariables) => {
        onCreate({ variables: values });
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={TemplateNotificationFormValidation}
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            validateOnMount={false}
        >
            <Form>
                <TemplateNotificationWrapper>
                    {currentSendFlow === SendFlowEnum.ByClientType ? (
                        <TemplateNotificationFormByTypeAndStatusFields />
                    ) : (
                        <TemplateNotificationFormByClientIdsFields />
                    )}
                    <TemplateNotificationButtonsWrapper>
                        <ButtonPrimary type={ButtonTypeEnum.Submit} disabled={isLoading}>
                            Save to drafts
                        </ButtonPrimary>
                        <ButtonBordered
                            disabled={isLoading}
                            onClick={() =>
                                navigate(PageEnum.EmailsAndNotifications, {
                                    [EmailsAndNotificationsTableFilterFormEnum.NotificationInstanceType]:
                                        NotificationInstanceTypeEnum.Notifications,
                                })
                            }
                        >
                            Cancel
                        </ButtonBordered>
                    </TemplateNotificationButtonsWrapper>
                </TemplateNotificationWrapper>
            </Form>
        </Formik>
    );
};
