import { useMutation } from '@apollo/client';
import { useRef, useState } from 'react';

import { AdminStartChangPhone, DefaultAnswer, Mutation } from '@shared-graphql';
import { useOutsideClick } from '@shared-hook/utils/use-outside-click';
import { isExist } from '@shared-util/is-data';

import { ClientSummaryEditPhoneFormEnum } from '@page/common/client-summary/client-summary-edit-phone-form/client-summary-edit-phone-form.enum';
import { ClientSummaryEditPhoneFormInterface } from '@page/common/client-summary/client-summary-edit-phone-form/client-summary-edit-phone-form.interface';

export const useClientSummaryEditPhoneForm = ({
    phone,
}: Pick<ClientSummaryEditPhoneFormInterface, ClientSummaryEditPhoneFormEnum.Phone>) => {
    const [isFormVisible, setFormVisible] = useState(false);
    const [canCloseFormOnOutsideClick, setCanCloseFormOnOutsideClick] = useState(false);
    const [adminStartChangPhone, { data, loading: isLoading, error, reset }] =
        useMutation<Pick<Mutation, 'adminStartChangPhone'>>(AdminStartChangPhone);
    const formRef = useRef<HTMLFormElement | null>(null);

    useOutsideClick(formRef, () => !canCloseFormOnOutsideClick && setFormVisible(false));

    const onSubmit = (values: Omit<ClientSummaryEditPhoneFormInterface, 'updateUserPhone'>) => {
        if (phone === values.phone) {
            setFormVisible(false);
        } else {
            adminStartChangPhone({ variables: values });
        }
    };

    return {
        error,
        formRef,
        isFormVisible,
        isLoading,
        onSubmit,
        setCanCloseFormOnOutsideClick,
        setFormVisible,
        shouldOpenOtpModal: isExist((data?.adminStartChangPhone as DefaultAnswer)?.status),
        reset,
    };
};
