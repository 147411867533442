import { Badge } from '@shared-component/badge';
import { BadgeSizeEnum, BadgeTypeEnum } from '@shared-component/badge/types';
import { Notification, SendingStatus, Template } from '@shared-graphql';
import { isTrue } from '@shared-util/is-data';

const sendingStatusToBadgeTypeMapping = {
    [SendingStatus.Created]: BadgeTypeEnum.WARNING_GHOST,
    [SendingStatus.Sent]: BadgeTypeEnum.SUCCESS_GHOST,
};
const sendingStatusToBadgeTitleMapping = {
    [SendingStatus.Created]: 'Created',
    [SendingStatus.Sent]: 'Sent',
};

export const StatusCell = ({ sendingStatus }: Template | Notification) => {
    const badgeType = sendingStatusToBadgeTypeMapping[sendingStatus];
    const badgeTitle = sendingStatusToBadgeTitleMapping[sendingStatus];

    return isTrue(badgeType) && isTrue(badgeTitle) ? (
        <Badge type={badgeType} size={BadgeSizeEnum.S} title={badgeTitle} />
    ) : (
        '-'
    );
};
