export enum PageEnum {
    Login = '/login',

    StatusClients = '/clients',
    Transactions = '/clients/transactions',
    AdminCards = '/clients/cards',
    Currencies = '/clients/currencies',
    CreateClient = '/clients/create',
    LegalClientCreate = '/clients/:userId/legal/create',
    CommissionProfile = '/clients/commission-profile',
    EmailsAndNotifications = '/clients/emails-and-notifications',

    PrivateClient = '/clients/:userId/private',
    PrivateClientSummary = '/clients/:userId/private/summary',
    PrivateClientDocuments = '/clients/:userId/private/documents',
    PrivateClientArchivedDocuments = '/clients/:userId/private/documents/archived',
    PrivateClientAccounts = '/clients/:userId/private/accounts',
    PrivateClientComments = '/clients/:userId/private/comments',
    PrivateClientServices = '/clients/:userId/private/client-services',
    PrivateClientPricing = '/clients/:userId/private/pricing',
    PrivateClientProfilePersonal = '/clients/:userId/private/profile/personal',
    PrivateClientProfileAddress = '/clients/:userId/private/profile/address',
    PrivateClientProfileServices = '/clients/:userId/private/profile/client-services',
    PrivateClientProfileDocuments = '/clients/:userId/private/profile/documents',
    PrivateClientProfileDeclarations = '/clients/:userId/private/profile/declarations',
    PrivateClientProfileAcceptAgreement = '/clients/:userId/private/profile/accept-agreement',

    LegalClient = '/clients/:companyId/legal',
    LegalClientSummary = '/clients/:companyId/legal/summary',
    LegalClientAccounts = '/clients/:companyId/legal/accounts',
    LegalClientDocuments = '/clients/:companyId/legal/documents',
    LegalClientArchivedDocuments = '/clients/:companyId/legal/documents/archived',
    LegalClientAdministration = '/clients/:companyId/legal/administration',
    LegalClientPricing = '/clients/:companyId/legal/pricing',
    LegalClientServices = '/clients/:companyId/legal/client-services',
    LegalClientProfileInformation = '/clients/:companyId/legal/profile/information',
    LegalClientProfileAddress = '/clients/:companyId/legal/profile/address',
    LegalClientProfilePersons = '/clients/:companyId/legal/profile/persons',
    LegalClientProfileDocuments = '/clients/:companyId/legal/profile/documents',
    LegalClientProfileServices = '/clients/:companyId/legal/profile/client-services',
    LegalClientProfileShareholders = '/clients/:companyId/legal/profile/shareholders',

    Payment = '/clients/payment/:paymentId/client/:clientId',
    PaymentEdit = '/clients/payment/:paymentId/client/:clientId/edit',
    PaymentSummary = '/clients/payment/:paymentId/client/:clientId/summary',
    PaymentDocuments = '/clients/payment/:paymentId/client/:clientId/documents',

    EmailCreate = '/clients/email/create',
    EmailView = '/clients/email/view/:id',
    EmailEdit = '/clients/email/edit/:id',

    NotificationCreate = '/clients/notification/create',
    NotificationView = '/clients/notification/view/:id',
    NotificationEdit = '/clients/notification/edit/:id',
}
