import { useMemo } from 'react';

import { ButtonPrimaryStyle } from '@shared-atom/button/button.style';
import { useCurrentNavigation, useRouterNavigate } from '@shared-hook/navigation/use-current-navigation.hook';

import { PageEnum } from '@enum/page.enum';
import { NotificationInstanceTypeEnum } from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications.enum';
import {
    EmptyTableComponentInfoText,
    EmptyTableComponentWrapper,
} from '@page/emails-and-notifications/emails-and-notifications-table/empty-table-component/empty-table-component.styles';

export const EmptyTableComponent = () => {
    const navigate = useRouterNavigate();
    const { searchParams } = useCurrentNavigation();

    const { notificationInstanceType } = searchParams;

    const infoText = useMemo(
        () =>
            `No ${
                notificationInstanceType === NotificationInstanceTypeEnum.Notifications ? 'notifications' : 'emails'
            } found.\nAdjust your search criteria or create a new one.`,
        [notificationInstanceType]
    );
    const buttonText = useMemo(
        () =>
            `Create ${
                notificationInstanceType === NotificationInstanceTypeEnum.Notifications ? 'notification' : 'email'
            }`,
        [notificationInstanceType]
    );

    return (
        <EmptyTableComponentWrapper>
            <EmptyTableComponentInfoText>{infoText}</EmptyTableComponentInfoText>
            <ButtonPrimaryStyle
                onClick={() =>
                    navigate(
                        notificationInstanceType === NotificationInstanceTypeEnum.Notifications
                            ? PageEnum.NotificationCreate
                            : PageEnum.EmailCreate
                    )
                }
            >
                {buttonText}
            </ButtonPrimaryStyle>
        </EmptyTableComponentWrapper>
    );
};
