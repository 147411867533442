import { useFilterByNotificationInstanceType } from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications-table-filter/filter-by-notification-instance-type/filter-by-notification-instance-type.hook';
import { FilterByNotificationInstanceInterface } from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications-table-filter/filter-by-notification-instance-type/filter-by-notification-instance-type.interface';
import {
    FilterByNotificationInstanceTypeButton,
    FilterByNotificationInstanceTypeContainer,
} from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications-table-filter/filter-by-notification-instance-type/filter-by-notification-instance-type.styles';
import { NotificationInstanceTypeEnum } from '@page/emails-and-notifications/emails-and-notifications-table/emails-and-notifications.enum';

export const FilterByNotificationInstanceType = ({
    notificationInstanceTypeFilter,
    onNotificationInstanceTypeChange,
}: FilterByNotificationInstanceInterface) => {
    useFilterByNotificationInstanceType({ notificationInstanceTypeFilter, onNotificationInstanceTypeChange });

    return (
        <FilterByNotificationInstanceTypeContainer>
            <FilterByNotificationInstanceTypeButton
                isActive={notificationInstanceTypeFilter === NotificationInstanceTypeEnum.Templates}
                onClick={() => onNotificationInstanceTypeChange(NotificationInstanceTypeEnum.Templates)}
            >
                Emails
            </FilterByNotificationInstanceTypeButton>
            <FilterByNotificationInstanceTypeButton
                isActive={notificationInstanceTypeFilter === NotificationInstanceTypeEnum.Notifications}
                onClick={() => onNotificationInstanceTypeChange(NotificationInstanceTypeEnum.Notifications)}
            >
                Notifications
            </FilterByNotificationInstanceTypeButton>
        </FilterByNotificationInstanceTypeContainer>
    );
};
