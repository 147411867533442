import { useCallback, useContext, useMemo } from 'react';

import { ButtonBordered } from '@shared-atom/button/button-bordered';
import { ButtonPrimary } from '@shared-atom/button/button-primary';
import { ButtonTypeEnum } from '@shared-atom/button/button-type.enum';
import { ModelContext } from '@shared-component/modal/modal-context';

import { ModalWrapper } from '@component/modal/admin-modal.styles';
import { ButtonSubmitWrapper, ConfirmationText } from '@component/modal/modals/conformation/confirmation.styles';

export const ConfirmationModal = () => {
    const { onClose, props } = useContext(ModelContext);

    const confirmText = useMemo(() => props?.componentProps?.confirmText, [props?.componentProps]);
    const confirmBtnText = useMemo(() => props?.componentProps?.confirmBtnText, [props?.componentProps]);
    const onConfirmClick = useMemo(() => props?.componentProps?.onConfirmClick, [props?.componentProps]);

    const confirmClickHandler = useCallback(() => {
        onConfirmClick();
        onClose();
    }, [onConfirmClick]);

    return (
        <ModalWrapper>
            <ConfirmationText>{confirmText}</ConfirmationText>
            <ButtonSubmitWrapper>
                <ButtonPrimary type={ButtonTypeEnum.Button} onClick={confirmClickHandler}>
                    {confirmBtnText}
                </ButtonPrimary>
                <ButtonBordered onClick={onClose}>Cancel</ButtonBordered>
            </ButtonSubmitWrapper>
        </ModalWrapper>
    );
};
