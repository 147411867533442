import { PageEnum } from '@enum/page.enum';

export const breadcrumbList = [
    {
        path: PageEnum.EmailsAndNotifications,
        name: 'Emails & Notifications',
    },
    {
        name: 'Create notification',
    },
];
