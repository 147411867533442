import { flow, getParent, Instance } from 'mobx-state-tree';

import { AdminGetCompany, Query, UserCompanyActive } from '@shared-graphql';
import { initialCompanyModel } from '@shared-model/company-model/company-model.initial';
import { OnEventEmptyType } from '@shared-type/on-event.type';

import { apolloClient } from '@connection/apollo-client';
import { CompanyModel } from '@model/company-model/company-model';
import { CompanyModelType } from '@model/company-model/company-model.type';

import { companyApplyActions } from './company-apply.actions ';

interface CompanyMainActionsInterface
    extends Instance<typeof CompanyModelType>,
    ReturnType<typeof companyApplyActions> {}

const queryCompany = (companyId: string) => () =>
    apolloClient
        .query<Pick<Query, 'adminGetCompany'>>({ query: AdminGetCompany, variables: { companyId } })
        .then(result => result.data.adminGetCompany);

export const companyMainActions = (self: CompanyMainActionsInterface) => ({
    applyCompanyMutation: flow(function* applyCompanyMutation<T>(mutation: OnEventEmptyType<Promise<T>>) {
        const companyModel = getParent(self) as Instance<typeof CompanyModel>;

        if (companyModel.loading.isCompanyLoading) {
            return;
        }

        companyModel.setCompanyLoading(true);

        try {
            const company = yield mutation();

            self.applyCompany(company ?? initialCompanyModel);
        } catch (error) {
            throw new Error(error as string);
        } finally {
            companyModel.setCompanyLoading(false);
        }
    }),

    loadCompany: (companyId: string) =>
        (self as unknown as ReturnType<typeof companyMainActions>).applyCompanyMutation(queryCompany(companyId)),

    setCompanyOwnerPhone: (phone: string) => {
        self.owner.phone = phone;
    },

    clearCompany: () => self.applyCompany(initialCompanyModel as unknown as UserCompanyActive),
});
