import { SendFlowEnum } from '@page/emails-and-notifications/components/send-flow-selector/send-flow-selector.enum';
import { SendFlowSelectorPropsInterface } from '@page/emails-and-notifications/components/send-flow-selector/send-flow-selector.interface';
import {
    SendFlowSelectorButton,
    SendFlowSelectorContainer,
} from '@page/emails-and-notifications/components/send-flow-selector/send-flow-selector.styles';

export const SendFlowSelector = ({ currentSendFlow, setCurrentSendFlow }: SendFlowSelectorPropsInterface) => (
    <SendFlowSelectorContainer>
        <SendFlowSelectorButton
            isActive={currentSendFlow === SendFlowEnum.ByClientType}
            onClick={() => setCurrentSendFlow(SendFlowEnum.ByClientType)}
        >
            Client type
        </SendFlowSelectorButton>
        <SendFlowSelectorButton
            isActive={currentSendFlow === SendFlowEnum.ByClientId}
            onClick={() => setCurrentSendFlow(SendFlowEnum.ByClientId)}
        >
            Client by ID
        </SendFlowSelectorButton>
    </SendFlowSelectorContainer>
);
